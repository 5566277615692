// import axios from '@/libs/axios'
export default {
  namespaced: true,
  state: {
    errors_back: [],
    showDismissibleAlert: false,
    indexTableUrl: 'ads/data-table/customize?page=',
    adData:null,
    adsFilterDataTable: {
      brands: [],
      users: [],
      categories: [],
      items: [],
      statusData: [],
      minPrice: null,
      maxPrice: null,
    },
    requestedFilterData: {
      brandModel: {
        name: 'Select Brand',
      },
      userModel: {
        name: 'Select User',
      },
      categoriesModel: { name: 'Select Category' },
      itemsModel: { name: 'Select Item' },
      minPrice: 100,
      maxPrice: 10000,
      orderBy: 'updated_at',
      sortBy: 'desc',
      searchData: null,

    },
    indexTableFields: [
      {
        label: 'Id',
        field: 'id',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true,
      },
      {
        label: 'Image',
        field: 'item_cover',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Code',
        field: 'code',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true,
      },
      {
        label: 'Name',
        field: 'name',
        tdClass: 'text-center',
        thClass: 'text-center',
        width: '200px',
      },
      {
        label: 'Status',
        field: 'status',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'User Name',
        field: 'user_name',
        tdClass: 'text-center',
        thClass: 'text-center',
        width: '200px',
      },
      {
        label: 'Phone',
        field: 'phone',
        tdClass: 'text-center',
        thClass: 'text-center',
        width: '200px',
      },
      {
        label: 'City Name',
        field: 'city_name',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Sale Price',
        field: 'sale_price',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true,
      },
      {
        label: 'Battery Health',
        field: 'battery_health',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Created At',
        field: 'created_at',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Updated At',
        field: 'updated_at',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
      {
        label: 'Action',
        field: 'actions',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ],
    indexTableTotalRow:1,
    indexTableData: [],
    orderTableOptions: [
      {
        text: 'Sale Price',
        value: 'sale_price',
      },
      {
        text: 'Id',
        value: 'id',
      },
      {
        text: 'Created at',
        value: 'created_at',
      },
      {
        text: 'Updated at',
        value: 'updated_at',
      },
    ],
    sortTableOptions: [
      {
        text: 'Ascending',
        value: 'asc',
      },
      {
        text: 'Descending',
        value: 'desc',
      },
    ],
  },
  getters: {},
  mutations: {
    // insert errors data
    setErrorsBack(state, payload) {
      state.errors_back = payload
      // console.log("from mutation" + state.errors_back)
    },
    // insert disnissaibleAlert
    setShowDisnissaibleAlert(state, payload) {
      state.showDismissibleAlert = payload
    },
    // reset errors data
    removeErrorsData(state) {
      state.errors_back = []
      state.showDismissibleAlert = false
    },
    
    setFilterDataTable(state, payload) {
      state.adsFilterDataTable.users = payload.users
      state.adsFilterDataTable.brands = payload.brands
      state.adsFilterDataTable.categories = payload.categories
      state.adsFilterDataTable.items = payload.items
      // console.log(state.adsFilterDataTable.items)

      state.adsFilterDataTable.statusData = payload.status
      state.adsFilterDataTable.minPrice = Number(payload.min_price)
      state.adsFilterDataTable.maxPrice = Number(payload.max_price)
    },
    setIndexTableData(state , payload){
      state.indexTableData = payload
    },
    resetRequestedFilterData(state){

      // state.requestedFilterData
      state.requestedFilterData={
        brandModel: {
          name: 'Select Brand',
        },
        userModel: {
          name: 'Select User',
        },
        categoriesModel: { name: 'Select Category' },
        itemsModel: { name: 'Select Item' },
        minPrice: 100,
        maxPrice: 10000,
        orderBy: 'updated_at',
        sortBy: 'desc',
        searchData: null,
      }
    },
    setAdData(state , payload){
      state.adData = payload
      state.adData.addReportData = []
      state.adData.addReportData.push(payload.report)

      // state.adDataReport = payload.report
    }

  },
  actions: {
    getAdsFilterDataTable({ state, commit }) {
      let errors_back = []
      let showDismissibleAlert = false
      axios
        .get('ads/data-table/filter')
        .then((response) => {
          showDismissibleAlert = false
          commit('setFilterDataTable', response.data.data)
        })
        .catch((err) => {
          // console.log(err)
          showDismissibleAlert = true

          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
          } else {
            errors_back.push({
              error: 'internal server error',
            })
          }
          
          commit('setErrorsBack', errors_back)
          commit('setShowDisnissaibleAlert', showDismissibleAlert)
        })

      // commit('removeErrorsData')
    },

    getAdsDataTable({state , commit , dispatch} , paginationData) {
     
      let errors_back = []
      let showDismissibleAlert = false
      let requestedData = state.requestedFilterData
      var data = {
        
        pagi: paginationData.perpage,
        // sort: this.sortBy,
        // order_by: this.orderBy,
      }
      if (requestedData.orderBy != null) {
        data['order_by'] = requestedData.orderBy
      }
      if (requestedData.sortBy != null) {
        data['sort'] = requestedData.sortBy
      }
      if (requestedData.searchData != null) {
        data['search'] = requestedData.searchTerm
      }
      
      if (Object.keys(requestedData.brandModel).includes('id')) {
        data['brand_id'] = requestedData.brandModel.id
      }
      if (Object.keys(requestedData.categoriesModel).includes('id')) {
        data['category_id'] = requestedData.categoriesModel.id
      }
      if (Object.keys(requestedData.itemsModel).includes('id')) {
        data['item_id'] = requestedData.itemsModel.id
      }
      if (Object.keys(requestedData.userModel).includes('id')) {
        data['user_id'] = requestedData.userModel.id
      }
      
      if (requestedData.statusModel != null) {
        data['status'] = requestedData.statusModel
      }
      if (requestedData.minPrice != null) {
        data['min_price'] = requestedData.minPrice
      }
      if (requestedData.maxPrice != null) {
        data['max_price'] = requestedData.maxPrice
      }
      if (requestedData.maxPrice <= requestedData.minPrice) {
     
        showDismissibleAlert = true
        errors_back.push([['Max price must be greater than min price']])
        setTimeout(() => {
          requestedData.showDismissibleAlert = false
        }, 3000)
      }

      axios
        .post(state.indexTableUrl + paginationData.currentPage, data)
        .then((response) => {
          showDismissibleAlert = false
          
          // console.log(response)
          state.indexTableTotalRow = response.data.data.meta.total
          commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          // console.log(err)

          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)

    },
    async updateBulkAdsStatus({state , commit , dispatch} , {ads ,status , note})
    {
      // console.log(ads , note)
      let errors_back = []
      let showDismissibleAlert = false
      let requestedData = {}
      requestedData['ads'] = ads
      requestedData['status'] = status
      if(note != null){
        
        requestedData['note'] = note
      }
      await axios
        .post("ads/update-status/pluck", requestedData)
        .then((response) => {
          showDismissibleAlert = false
          
          
          // console.log(response)
          // state.indexTableTotalRow = response.data.data.meta.total
          // commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          // console.log(err)

          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)


    },

    getAdData({state , commit , dispatch} , id)
    {
      let errors_back = []
      let showDismissibleAlert = false
      state.showDismissibleAlert =false 
      state.errors_back =[] 
      axios
        .get("ads/" +id )
        .then((response) => {
          showDismissibleAlert = false
          
          
          // console.log(response)
          // state.indexTableTotalRow = response.data.data.meta.total
          commit('setAdData', response.data.data)
       
        })
        .catch((err) => {
          console.log(err)

          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
        commit('setShowDisnissaibleAlert', showDismissibleAlert)
      })


    },

    async updateAdStatus({state , commit , dispatch} , {id , status , note})
    {

      console.log(id , status);
      let errors_back = []
      let showDismissibleAlert = false
      let requestedData = {}
      requestedData['status'] = status
      if(note != null){
        
        requestedData['note'] = note
      }
      await axios
        .post("ads/"+ id +"/status/change" , requestedData)
        .then((response) => {
          showDismissibleAlert = false
          
          
          // console.log(response)
          // state.indexTableTotalRow = response.data.data.meta.total
          // commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          console.log(err)
          showDismissibleAlert = true
 
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)


    },
    async storeAdLogger({state , commit , dispatch} , {id , note})
    {

      
      let errors_back = []
      let showDismissibleAlert = false
      let requestedData = {}
      if(note != null){
        
        requestedData['note'] = note
      }else{
        showDismissibleAlert = true
        errors_back.push({
          error: 'note data is required',
        })
        commit('setErrorsBack', errors_back)

        commit('setShowDisnissaibleAlert', showDismissibleAlert)
          return;
      }
    
      await axios
        .post("ads/"+ id +"/logger/store" , requestedData)
        .then((response) => {
          showDismissibleAlert = false
          
          // console.log(response.data.data)
          state.adData.loggers = response.data.data
          // state.indexTableTotalRow = response.data.data.meta.total
          // commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          console.log(err)
          showDismissibleAlert = true
 
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)


    },
    async archiveAd({state , commit , dispatch} , {id , note})
    {

      
      let errors_back = []
      let showDismissibleAlert = false
      let requestedData = {}
      
      
      await axios
        .delete("ads/"+ id )
        .then((response) => {
          showDismissibleAlert = false
          
          // console.log(response.data.data)
          state.adData.deleted_at = response.data.data.deleted_at
          state.adData.updated_at = response.data.data.updated_at
          // state.indexTableTotalRow = response.data.data.meta.total
          // commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          console.log(err)
          showDismissibleAlert = true
 
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)


    },
    async restoreAd({state , commit , dispatch} , {id })
    {

      
      let errors_back = []
      let showDismissibleAlert = false

      
    
      await axios
        .get("ads/"+ id +"/restore" )
        .then((response) => {
          showDismissibleAlert = false
          
          // console.log(response.data.data)
          state.adData.deleted_at = response.data.data.deleted_at
          state.adData.updated_at = response.data.data.updated_at
          // state.indexTableTotalRow = response.data.data.meta.total
          // commit('setIndexTableData', response.data.data.data)
       
        })
        .catch((err) => {
          console.log(err)
          showDismissibleAlert = true
 
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            
          }
          
        // console.log(errors_back)
        // commit('removeErrorsData')
        commit('setErrorsBack', errors_back)
      })
      commit('setShowDisnissaibleAlert', showDismissibleAlert)


    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    humanize(str) {
      var i,
        frags = str.split('_')
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    },
  },
}
