// import axios from '@/libs/axios'
export default {
  namespaced: true,
  state: {
    settings: [],
    setting: {},
    settingWithLocale: {},
    singleMediaData: [
      {
        locale: 'ar',
        single_media_array: [
          {
            image: null,
            alt: null,
            link: null,
            size: 'Web',
            imagePreview: null,
          },
          {
            image: null,
            alt: null,
            link: null,
            size: 'Tablet',
            imagePreview: null,
          },
          {
            image: null,
            alt: null,
            link: null,
            size: 'Mobile',
            imagePreview: null,
          },
        ],
      },
      {
        locale: 'en',
        single_media_array: [
          {
            image: null,
            alt: null,
            link: null,
            size: 'Web',
            imagePreview: null,
          },
          {
            image: null,
            alt: null,
            link: null,
            size: 'Tablet',
            imagePreview: null,
          },
          {
            image: null,
            alt: null,
            link: null,
            size: 'Mobile',
            imagePreview: null,
          },
        ],
      },
    ],
    ArrayMediaData: [
      {
        locale: 'ar',
        sizeMediaArray: [
          {
            size: 'Web',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
          {
            size: 'Tablet',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
          {
            size: 'Mobile',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
        ],
      },
      {
        locale: 'en',
        sizeMediaArray: [
          {
            size: 'Web',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
          {
            size: 'Tablet',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
          {
            size: 'Mobile',
            imageArray: [
              {
                image: null,
                alt: null,
                link: null,
                imagePreview: null,
              },
            ],
          },
        ],
      },
    ],
    staticPagesData: [
      {
        locale: 'ar',
        text: null,
      },
      {
        locale: 'en',
        text: null,
      },
    ],
    // creating setting data
    newSettingData: [
      {
        attribute: null,
        type: null,
        data_type: null,
        value: null,
        staticPagesLocaleArray: [
          {
            locale: 'ar',
            text: null,
          },
          {
            locale: 'en',
            text: null,
          },
        ],
        mediaLocaleArrayData: [
          {
            locale: 'ar',
            sizeMediaArray: [
              {
                size: 'Web',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
              {
                size: 'Tablet',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
              {
                size: 'Mobile',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
            ],
          },
          {
            locale: 'en',
            sizeMediaArray: [
              {
                size: 'Web',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
              {
                size: 'Tablet',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
              {
                size: 'Mobile',
                imageArray: [
                  {
                    image: null,
                    alt: null,
                    link: null,
                    imagePreview: null,
                  },
                ],
              },
            ],
          },
        ],
        singleMediaWithLocale: [
          {
            locale: 'ar',
            single_media_array: [
              {
                image: null,
                alt: null,
                link: null,
                size: 'Web',
                imagePreview: null,
              },
              {
                image: null,
                alt: null,
                link: null,
                size: 'Tablet',
                imagePreview: null,
              },
              {
                image: null,
                alt: null,
                link: null,
                size: 'Mobile',
                imagePreview: null,
              },
            ],
          },
          {
            locale: 'en',
            single_media_array: [
              {
                image: null,
                alt: null,
                link: null,
                size: 'Web',
                imagePreview: null,
              },
              {
                image: null,
                alt: null,
                link: null,
                size: 'Tablet',
                imagePreview: null,
              },
              {
                image: null,
                alt: null,
                link: null,
                size: 'Mobile',
                imagePreview: null,
              },
            ],
          },
        ],
      },
    ],
    settingTypeOptions: ['General', 'Banners', 'Slider', 'Static Pages'],
    boolOptions: ['true', 'false'],
    settingGeneralDataTypeOptions: [
      {
        value: 'select_value',
        text: 'select_value',
      },
      {
        value: 'url',
        text: 'Url',
      },
      {
        value: 'string',
        text: 'String',
      },
      {
        value: 'boolean',
        text: 'boolean',
      },
      {
        value: 'float',
        text: 'Float',
      },
      {
        value: 'email',
        text: 'Email',
      },
    ],
    settingBannerTypeOptions: [
      {
        value: 'select_value',
        text: 'select_value',
      },
      {
        value: 'single_media',
        text: 'Single Media',
      },
    ],
    settingSliderTypeOptions: [
      {
        value: 'select_value',
        text: 'select_value',
      },
      {
        value: 'media_array',
        text: 'Media Array',
      },
    ],
    settingStaticPagesDataTypeOptions: [
      {
        value: 'select_value',
        text: 'select_value',
      },
      {
        value: 'text',
        text: 'Text',
      },
    ],
    file: null,
    settingTypes: [],
    settingType: 'all',
    errors_back: [],
    showDismissibleAlert: null,
    customToolbar: [
      [{ font: ['cairo'] }],

      [{ header: [false, 1, 2, 3, 4, 5, 6] }],

      [{ size: ['small', false, 'large', 'huge'] }],

      ['bold', 'italic', 'underline', 'strike'],

      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],

      [{ header: 1 }, { header: 2 }],

      ['blockquote', 'code-block'],

      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

      [{ script: 'sub' }, { script: 'super' }],

      [{ indent: '-1' }, { indent: '+1' }],

      [
        {
          color: [
            '#000000',
            '#0C090A',
            '#34282C',
            '#3B3131',
            '#3A3B3C',
            '#454545',
            '#413839',
            '#3D3C3A',
            '#463E3F',
            '#4C4646',
            '#504A4B',
            '#565051',
            '#52595D',
            '#5C5858',
            '#625D5D',
            '#616D7E',
            '#36454F',
            '#CECECE',
            '#B6B6B4',
            '#8D918D',
            '#808080',
            '#757575',
            '#797979',
            '#C9C0BB',
            '#C0C0C0',
            '#DCDCDC',
            '#D3D3D3',
            '#FAF0E6',
            '#FFF5EE',
            '#FFFFFF',
            '#FEFCFF',
            '#FFFAFA',
            '#FBFBF9',
            '#F5F5F5',
            '#FFFFF7',
            '#FFFFF0',
            '#FFFAF0',
            '#FAF5EF',
            '#FFF5EE',
            '#08308B',
            '#000080',
            '#151B8D',
            '#00008B',
            '#15317E',
            '#0000A0',
            '#0000A5',
            '#0020C2',
            '#0000CD',
            '#00BFFF',
            '#38ACEC',
            '#79BAEC',
            '#82CAFF',
            '#87CEFA',
            '#3BB9FF',
            '#8C9EFF',
            '#954535',
            '#A0522D',
            '#8B4513',
            '#8A4117',
            '#7E3817',
            '#7E3517',
            '#C34A2C',
            '#C04000',
            '#C35817',
            '#B86500',
            '#D2691E',
            '#CC6600',
            '#E56717',
            '#E66C2C',
            '#FF6700',
            '#FF5F1F',
            '#F87217',
            '#F88017',
            '#FF8C00',
            '#ED6009',
            '#FF0000',
            '#FD1C03',
            '#FF2400',
            '#F62217',
            '#F70D1A',
            '#F62817',
            '#E42217',
            '#B71C1C',
          ],
        },
        {
          background: [
            '#000000',
            '#0C090A',
            '#34282C',
            '#3B3131',
            '#3A3B3C',
            '#454545',
            '#413839',
            '#3D3C3A',
            '#463E3F',
            '#4C4646',
            '#504A4B',
            '#565051',
            '#52595D',
            '#5C5858',
            '#625D5D',
            '#616D7E',
            '#36454F',
            '#CECECE',
            '#B6B6B4',
            '#8D918D',
            '#808080',
            '#757575',
            '#797979',
            '#C9C0BB',
            '#C0C0C0',
            '#DCDCDC',
            '#D3D3D3',
            '#FAF0E6',
            '#FFF5EE',
            '#FFFFFF',
            '#FEFCFF',
            '#FFFAFA',
            '#FBFBF9',
            '#F5F5F5',
            '#FFFFF7',
            '#FFFFF0',
            '#FFFAF0',
            '#FAF5EF',
            '#FFF5EE',
            '#08308B',
            '#00BFFF',
            '#38ACEC',
            '#79BAEC',
            '#82CAFF',
            '#87CEFA',
            '#3BB9FF',
            '#8C9EFF',
            '#954535',
            '#A0522D',
            '#8B4513',
            '#8A4117',
            '#7E3817',
            '#7E3517',
            '#C34A2C',
            '#C04000',
            '#C35817',
            '#B86500',
            '#D2691E',
            '#CC6600',
            '#E56717',
            '#E66C2C',
            '#FF6700',
            '#FF5F1F',
            '#F87217',
            '#F88017',
            '#FF8C00',
            '#ED6009',
            '#FF0000',
            '#FD1C03',
            '#FF2400',
            '#F62217',
            '#F70D1A',
            '#F62817',
            '#E42217',
            '#B71C1C',
          ],
        },
      ],

      ['link', 'image', 'video', 'formula'],

      [{ direction: 'rtl' }, { direction: 'ltr' }],
      ['clean'],
    ],
  },
  getters: {},
  mutations: {
    setSettings(state, payload) {
      state.settings = payload
      // console.log(state.settings)
    },
    setSetting(state, payload) {
      state.setting = payload
    },
    setSettingWithLocale(state, payload) {
      state.settingWithLocale.attribute = payload.attribute
      state.settingWithLocale.type = payload.type
      state.settingWithLocale.data_type = payload.data_type
      state.settingWithLocale.active = payload.active

      if (payload.data_type == 'text') {
        state.settingWithLocale.staticPagesLocaleArray = payload.textDataArray
      } else if (payload.data_type == 'single_media') {
        state.settingWithLocale.singleMediaData = payload.singleMediaData
      } else if (payload.data_type == 'media_array') {
        state.settingWithLocale.mediaLocaleArrayData = payload.mediaLocaleArray
      }
      // console.log(state.settingWithLocale)
    },
    clearSettingWithLocaleData(state) {
      state.settingWithLocale = {}
    },
    UpdateSingleMediaImage(state, payload) {
      // console.log(payload.e, payload.indexLocale, payload.indexSize)
      const image = payload.e.target.files[0]
      if (payload.e != null) {
        state.settingWithLocale.singleMediaData[
          payload.indexLocale
        ].single_media_array[payload.indexSize].newImageData =
          URL.createObjectURL(image)

        var fileReader = new FileReader()
        fileReader.readAsDataURL(image)
      }
      // console.log('new data ', state.settingWithLocale)
    },
    // add new setting data
    addNewSetting(state) {
      state.newSettingData.push({
        attribute: null,
        type: null,
        data_type: null,
         // map media array data 
         mediaLocaleArrayData: state.ArrayMediaData.map(item => ({
          locale: item.locale,
          // map size media array
          sizeMediaArray : item.sizeMediaArray.map(sizeMedia => ({
            size:sizeMedia.size,
            // map media array data
            imageArray: sizeMedia.imageArray.map(media => ({
              ...media,
            }))
          }))
        })),
        // map static pages array
        staticPagesLocaleArray: state.staticPagesData.map(item => ({
          locale: item.locale,
          text:null
        })),
        singleMediaWithLocale: state.singleMediaData.map(item => ({
          locale: item.locale,
          single_media_array: item.single_media_array.map(media => ({
            ...media,
            imagePreview: null  
          }))
        }))
      })
    },
    // update the selected data_type
    updateSettingSelectedDataType(state, { e, index }) {
      state.newSettingData[index].data_type = e.value
      // reset the value of setting
      state.newSettingData[index].value = null
    },
    updateSettingSelectedType(state, { e, index }) {
      state.newSettingData[index].data_type = null
      // reset the value of setting
      state.newSettingData[index].value = null
    },
    // remove setting data from creating setting array by index
    removeSetting(state, payload) {
      state.newSettingData.splice(payload, 1)
    },
    // reset setting data from creating setting array by index
    resetNewSettingsData(state) {
      state.newSettingData = [
        {
          attribute: null,
          type: null,
          data_type: null,
          value: null,
          
          // map media array data 
          mediaLocaleArrayData: state.ArrayMediaData.map(item => ({
            locale: item.locale,
            // map size media array
            sizeMediaArray : item.sizeMediaArray.map(sizeMedia => ({
              size:sizeMedia.size,
              // map media array data
              imageArray: sizeMedia.imageArray.map(media => ({
                ...media,
              }))
            }))
          })),
          // map static pages array
          staticPagesLocaleArray: state.staticPagesData.map(item => ({
            locale: item.locale,
            text:null
          })),
          singleMediaWithLocale: state.singleMediaData.map(item => ({
            locale: item.locale,
            single_media_array: item.single_media_array.map(media => ({
              ...media,
            }))
          }))
        },
      ]
    },
    // insert errors data
    setErrorsBack(state, payload) {
      state.errors_back = payload
    },
    // insert disnissaibleAlert
    setShowDisnissaibleAlert(state, payload) {
      state.showDismissibleAlert = payload
    },
    // reset errors data
    removeErrorsData(state) {
      state.errors_back = []
      state.showDismissibleAlert = false
    },
    // reset set settings types
    setSettingTypes(state, payload) {
      state.settingTypes = payload
    },
    // reset errors data
    updateSettingTypeFiltering(state, payload) {
      state.settingType = payload
    },
    // reset errors data
    setFile(state, payload) {
      state.file = payload
    },
    // Add New media to Array in Setting Data
    addNewImageToMediaArrayWithLocale(state, payload) {
      state.newSettingData[payload.index].mediaLocaleArrayData[
        payload.indexLocale
      ].sizeMediaArray[payload.indexSize]['imageArray'].push({
        image: null,
        alt: null,
        link: null,
        imagePreview: null,
      })
    },
    // remove media from array in setting data
    removeImageFromMediaArrayWithLocale( state,{ index, indexLocale, arrayIndexSize, arrayIndex }) {
      state.newSettingData[index].mediaLocaleArrayData[
        indexLocale
      ].sizeMediaArray[arrayIndexSize].imageArray.splice(arrayIndex, 1)
    },
    updateSettingInIndexArray(state, updatedSetting) {
      let setting = state.settings.find((x) => x.id === updatedSetting.id)
      let settingIndex = state.settings.indexOf(setting)
      state.settings[settingIndex].attribute = updatedSetting.attribute
      state.settings[settingIndex].data_type = updatedSetting.data_type
      state.settings[settingIndex].type = updatedSetting.type
      state.settings[settingIndex].value = updatedSetting.value
      state.settings[settingIndex].active = updatedSetting.active
    },
  },
  actions: {
    async createSetting({ state, commit, dispatch }) {
      // remove errors data
      commit('removeErrorsData')
      let errors_back = []
      let showDismissibleAlert = false
      // form data for request data 
      let formData = new FormData()
      for (let index in state.newSettingData) {
        // append data_type
        formData.append('setting[' + index + '][data_type]',state.newSettingData[index].data_type)
        // append attribute
        formData.append('setting[' + index + '][attribute]',state.newSettingData[index].attribute)
        // append type
        formData.append('setting[' + index + '][type]',state.newSettingData[index].type)

        // append request data depend on the setting type
        if (state.newSettingData[index].data_type == 'media_array') {

          // append Media Array Data 
          state.newSettingData[index].mediaLocaleArrayData.reduce((formData, settingMediaArray, settingIndex) => {
            // set locale data
            formData.append(`setting[${index}][mediaArrayData][${settingIndex}][locale]`, settingMediaArray.locale)
            // make loop in size media array data
            settingMediaArray.sizeMediaArray.forEach((mediaSize, indexSize) => {
              formData.append(`setting[${index}][mediaArrayData][${settingIndex}][MediaSizeArray][${indexSize}][size]`,mediaSize.size)
              mediaSize.imageArray.forEach((media, i) => {
                formData.append(`setting[${index}][mediaArrayData][${settingIndex}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][alt]`,media.alt)
                formData.append(`setting[${index}][mediaArrayData][${settingIndex}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][image]`,media.image)  
                if(media.link != null){
                  formData.append(`setting[${index}][mediaArrayData][${settingIndex}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][link]`, media.link)
                }
              })
              
            })
            return formData
          }, formData)

        } else if (state.newSettingData[index].data_type == 'single_media') {

          state.newSettingData[index].singleMediaWithLocale.reduce((formData, settingSingleMedia, indexLocale) => {
            formData.append(`setting[${index}][singleMediaWithLocale][${indexLocale}][locale]`,settingSingleMedia.locale)

            settingSingleMedia.single_media_array.forEach((media, i) => {
              formData.append(`setting[${index}][singleMediaWithLocale][${indexLocale}][singleMediaSizeArray][${i}][alt]`,media.alt)
              formData.append(`setting[${index}][singleMediaWithLocale][${indexLocale}][singleMediaSizeArray][${i}][size]`,media.size)
              formData.append(`setting[${index}][singleMediaWithLocale][${indexLocale}][singleMediaSizeArray][${i}][image]`,media.image)
              if (media.link != null) {
                formData.append(`setting[${index}][singleMediaWithLocale][${indexLocale}][singleMediaSizeArray][${i}][link]`,media.link)
              }
            })
            return formData
          }, formData)

        } else if (state.newSettingData[index].data_type == 'text') {
          state.newSettingData[index].staticPagesLocaleArray.reduce((formData , staticSettingData , indexLocale) =>{
            formData.append(`setting[${index}][staticPagesArray][${indexLocale}][locale]`,staticSettingData.locale)
            formData.append(`setting[${index}][staticPagesArray][${indexLocale}][text]`,staticSettingData.text)

            return formData
          }, formData)
        } else {
          // check if the setting data_type equal to boolean
          if (state.newSettingData[index].data_type == 'boolean') {
            if (state.newSettingData[index].value == 'true') {
              state.newSettingData[index].value = true
            } else {
              state.newSettingData[index].value = false
            }
          }
          // append value
          formData.append('setting[' + index + '][value]',state.newSettingData[index].value)
        }
      }
      // //console.log(Object.fromEntries(formData))
      await axios
        .post('setting', formData)
        .then((result) => {
          showDismissibleAlert = false
        })
        .catch((err) => {
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    async getSettings({ dispatch, commit, state }) {
      // remove errors data
      commit('removeErrorsData')
      let errors_back = []
      let showDismissibleAlert = false
      await axios
        .get('setting/type/' + state.settingType + '/get')
        .then((result) => {
          showDismissibleAlert = false
          commit('setSettings', result.data.data)
          // //console.log(result.data.data)
        })
        .catch((err) => {
          // //console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    async getSettingTypes({ dispatch, commit }) {
      // remove errors data
      commit('removeErrorsData')
      let errors_back = []
      let showDismissibleAlert = false
      await axios
        .get('setting/types/get')
        .then((result) => {
          showDismissibleAlert = false
          commit('setSettingTypes', result.data.data)
          // //console.log(result.data.data)
        })
        .catch((err) => {
          //console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
          } else {
            errors_back.push({
              error: 'internal server error',
            })
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      // commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    async updateSetting({ dispatch, commit, state }, payload) {
      // //console.log(payload)
      commit('removeErrorsData')
      // remove errors data
      let errors_back = []
      let showDismissibleAlert = false
      let formData = new FormData()

      // append active
      formData.append('active', (payload.active) ? 1 : 0)

      if (payload.data_type == 'media_array') {
       
        // append the media array in the FromData
        payload.mediaLocaleArrayData.reduce((formData, settingMediaArray, index) => {
          // set locale data
          formData.append(`mediaArrayData[${index}][locale]`, settingMediaArray.locale)
          formData.append(`mediaArrayData[${index}][id]`, settingMediaArray.id)

          settingMediaArray.sizeMediaArray.forEach((mediaSize, indexSize) => {
            formData.append(`mediaArrayData[${index}][MediaSizeArray][${indexSize}][size]`,mediaSize.size)
            mediaSize.imageArray.forEach((media, i) => {
              formData.append(`mediaArrayData[${index}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][alt]`,media.alt)
              formData.append(`mediaArrayData[${index}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][link]`, media.link)
              formData.append(`mediaArrayData[${index}][MediaSizeArray][${indexSize}][imageMediaArray][${i}][imageBase64]`,media.imageBase64)
              
            })     
          })
          return formData
        }, formData)
        
      } else if (payload.data_type == 'single_media') {
        // append the mediaSizeArray in the FromData
        payload.singleMediaData.reduce((formData, setting, index) => {
          setting.single_media_array.forEach((media, i) => {
            formData.append(`singleMediaData[${index}][singleMediaSizeArray][${i}][alt]`,media.alt)
            formData.append(`singleMediaData[${index}][singleMediaSizeArray][${i}][link]`,media.link)
            formData.append(`singleMediaData[${index}][singleMediaSizeArray][${i}][size]`,media.size)
            if (media.newImage != null) {
              formData.append(  `singleMediaData[${index}][singleMediaSizeArray][${i}][newImage]`,  media.newImage)
            }
          })
          formData.append(`singleMediaData[${index}][locale]`, setting.locale)
          return formData
        }, formData)



      } else if (payload.data_type == 'text') {
        // append the mediaSizeArray in the FromData
        payload.staticPagesLocaleArray.reduce((formData, setting, index) => {
          formData.append(`staticPagesArray[${index}][locale]`, setting.locale)
          formData.append(`staticPagesArray[${index}][text]`, setting.text)
          return formData
        }, formData)
      } else {
        formData.append('value', payload.value)
      }

 
      await axios
        .post('setting/' + payload.attribute + '/update', formData)
        .then((result) => {
          showDismissibleAlert = false
          state.settingWithLocale.active = result.data.data.active
        })
        .catch((err) => {
          // console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },
    async storeImageEditor(
      { dispatch, commit },
      { payload, Editor, cursorLocation, resetUploader }
    ) {
      // remove errors data
      let errors_back = []
      let showDismissibleAlert = false

      let formData = new FormData()
      formData.append('image', payload)

      // //console.log(Object.fromEntries(formData))
      await axios
        .post('setting/image/store', formData)
        .then((result) => {
          showDismissibleAlert = false
          const url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          // //console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    async getSetting({ dispatch, commit }, payload) {
      // remove errors data
      let errors_back = []
      let showDismissibleAlert = false
      await axios
        .get('setting/' + payload)
        .then((result) => {
          showDismissibleAlert = false
          commit('setSetting', result.data.data)
          // //console.log(result.data.data)
        })
        .catch((err) => {
          console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    async ShowSettingWithLocale({ dispatch, commit }, payload) {
      // remove errors data
      let errors_back = []
      let showDismissibleAlert = false
      await axios
        .get('setting/' + payload)
        .then((result) => {
          showDismissibleAlert = false
          commit('setSettingWithLocale', result.data.data)
        })
        .catch((err) => {
          console.log(err)
          if (err.response.data.data != null) {
            if (dispatch('isString', err.response.data.data)) {
              errors_back.push({
                error: err.response.data.data,
              })
            } else {
              errors_back = err.response.data.data
            }
            showDismissibleAlert = true
          } else {
            errors_back.push({
              error: 'internal server error',
            })
            showDismissibleAlert = true
          }
        })

      commit('removeErrorsData')
      commit('setErrorsBack', errors_back)
      commit('setShowDisnissaibleAlert', showDismissibleAlert)
    },

    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    humanize(str) {
      var i,
        frags = str.split('_')
      for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
      }
      return frags.join(' ')
    },
  },
}
