import Vue from 'vue'

// axios
import axios from 'axios'
import store from '../store/index'
import { mapGetters } from 'vuex'
import * as rax from 'retry-axios'
import eventBus from './bus'
import eventHub from './eventhub'
import VueCryptojs from 'vue-cryptojs'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL + '/admin',
  // timeout: 3000,
  headers: {
    'Access-Control-Allow-Headers': 'Authorization',
    'Content-Type': 'application/json, multipart/form-data',
    'Access-Control-Allow-Origin': process.env.VUE_APP_BASE_URL + '/admin',
    'Access-Control-Allow-Methods': 'POST, PUT,GET,DELETE',
  },
})
// axiosIns.defaults.raxConfig = {
//     instance: axiosIns
// };
// const interceptorId = rax.attach(axiosIns);

// axiosIns.get('test')
//     .catch(err => {
//     })

axiosIns.interceptors.response.use(
  function (response) {
    eventBus.$emit('after-response')
    return response
  },
  function (error) {
    eventBus.$emit('response-error')
    if(error.response){
      if (error.response.status === 401 && !error.config.__isRetryRequest) {
        store.dispatch('axiosLogout')
      }
    }
    // if (error.response.status === 404 && !error.config.__isRetryRequest) {
    //     this.$router.push({ path: '' })
    // }
    return Promise.reject(error)
  }
)
import CryptoJS from 'crypto-js';

axiosIns.interceptors.request.use((config) => {
  eventBus.$emit('before-request');
  
  const access_token = localStorage.getItem('token');

  if (access_token) {
    // const encryptedToken = '';
    const isEncrypted = isProbablyEncrypted(access_token);

    if  (isEncrypted) {
      const decryptedToken = Vue.CryptoJS.AES.decrypt(access_token, 'encryptionSecret').toString(Vue.CryptoJS.enc.Utf8);
      config.headers.Authorization = `Bearer ${decryptedToken}`;
    } else {
      // Token is not encrypted, use it directly
      config.headers.Authorization = `Bearer ${access_token}`;
    }


    // const  encryptedToken = Vue.CryptoJS.AES.decrypt(access_token, 'encryptionSecret').toString(Vue.CryptoJS.enc.Utf8);
   
    // config.headers.Authorization = `Bearer ${encryptedToken}`;
  } else {
  }

  
function isProbablyEncrypted(token) {
 
  // const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  return  token.substring(0, 3) == 'U2F' ;
}

  return config;
}, (error) => {
  eventBus.$emit('request-error');
  return Promise.reject(error);
});

Vue.prototype.$http = axiosIns

export default axiosIns
