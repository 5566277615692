// import axios from '@/libs/axios'


export default {
  namespaced: true,
  state: {
    errors_back: [],
    showDismissibleAlert: false,
    
    
  },
  getters: {
  
   
  },
  mutations: {
      // insert errors data
    setErrorsBack(state, payload) {
      state.errors_back = payload
    },
    // insert disnissaibleAlert
    setShowDisnissaibleAlert(state, payload) {
      state.showDismissibleAlert = payload
    },
    // reset errors data
    removeErrorsData(state) {
      state.errors_back = []
      state.showDismissibleAlert = false
    },
  },
  actions: {
    isString(value) {
        return typeof value === 'string' || value instanceof String
      },
    
  },
  
}


