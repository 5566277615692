import Vue from 'vue'
import { ToastPlugin, ModalPlugin , BootstrapVue , VBPopover  } from 'bootstrap-vue'
// import VueCompositionAPI from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import BCardCode from "@core/components/b-card-code";
import JsonExcel from 'vue-json-excel'
import 'jspdf-autotable'

import vSelect from "vue-select";

import router from './router'
import store from './store'
import App from './App.vue'
import Vuex from 'vuex'
import axios from '@/libs/axios'
// import axios from 'axios'
import eventBus from '@/libs/bus'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// import Print from 'vue-print-nb'
// Global instruction

import VueSweetalert2 from 'vue-sweetalert2'

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css'

import VueVideoPlayer from 'vue-video-player'

// require videojs style
// import 'video.js/dist/video-js.css'
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
import InfiniteLoading from 'vue-infinite-loading'

Vue.use(InfiniteLoading, {
  /* options */
})
// import 'vue-video-player/src/custom-theme.css'

const MyPlugin = {
  install(Vue, options) {
    Vue.prototype.globalHelper = (str) => {
      str = str.replaceAll(/_/g, ' ')
      var splitStr = str.toLowerCase().split(' ')
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      // Directly return the joined string
      return splitStr.join(' ')
    }

    // Vue.prototype.perFun = () => {
    //   const permissions = localStorage.getItem('per')
    //   // split to get in array
    //   const myArr = permissions.split(',')

    //   const perArr = []
    //   for (let inde in myArr) {
    //     // decrypt
    //     perArr.push(
    //       Vue.CryptoJS.AES.decrypt(
    //         myArr[inde],
    //         'EncryptDataMobileMasr'
    //       ).toString(Vue.CryptoJS.enc.Utf8)
    //     )
    //   }

    //   return perArr
    // } //Vue.gPluginFun()
  },
}
Vue.use(MyPlugin)
// Vue.mixin({
//   methods: {
//     globalHelper: function(str){
//       str = str.replaceAll(/_/g, ' ')
//       var splitStr = str.toLowerCase().split(' ')
//       for (var i = 0; i < splitStr.length; i++) {
//         // You do not need to check if i is larger than splitStr length, as your for does that for you
//         // Assign it back to the array
//         splitStr[i] =
//           splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
//       }
//       // Directly return the joined string
//       return splitStr.join(' ')
//     }
//   }
// })
Vue.use(
  VueVideoPlayer
  /* {
  options: global default options,
  events: global videojs events
} */
)
Vue.use(VueSweetalert2)

import VueSession from 'vue-session'
Vue.use(VueSession)

Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false
Vue.config.performance = true

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(Vuex)

Vue.component('BCardCode', BCardCode)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('vSelect', vSelect)
Vue.component('downloadExcel', JsonExcel)


Vue.directive('heightFade', heightFade);
Vue.directive('ripple', Ripple);
Vue.directive('b-popover', VBPopover)
// Vue.directive('b-tooltip', VBTooltip)
// Vue.use(axios)
// Vue.use(Print)


import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

import loading from 'vuejs-loading-screen'

Vue.use(loading, {
  bg: 'transparent',
  icon: 'circle-o-notch',
  size: 5,
  icon_color: '#1e282c',
})

window.Bus = new Vue()
window.axios = axios
const session = Vue.prototype.$session

store.dispatch('appConfig/setLanguage', session.get('lang'))

var URL =
  process.env.VUE_APP_BASE_URL + '/admin/' 

// eventBus.$on('lang', () => {
//   URL =
//     process.env.VUE_APP_BASE_URL + '/admin/' 
//   axios.defaults.baseURL = URL
// })
import moment from 'moment';

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('MM/DD/YYYY hh:mm');
  }
});

Vue.filter('formatDateAgo', function(value) {
  if (value) {
      return moment(String(value)).fromNow();
  }
});

axios.defaults.baseURL = URL
// if (window.localStorage.getItem("token") !== null) {
//   store.dispatch('getAuthenticatedPermissions')
// }

// Composition API

// Composition API
// Vue.use(VueCompositionAPI)

// import { func } from '@/libs/per.js'
// Vue.prototype.$func = func

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
// import assets styles
require('@core/scss/vue/libs/vue-flatpicker.scss')

require('@core/scss/vue/libs/vue-select.scss')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
