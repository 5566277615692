import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCryptojs from 'vue-cryptojs'
// import isUserLoggedIn from './authutlis.js'
// import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/HomeV2.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: 'https://mobilemasr.com/',
    //   name: 'Website',

    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    // *===============================================---*
    // *--------- Admin ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/admin/add',
      name: 'Register-Admin',
      component: () =>
        import('@/views/Administration/RegisterAdmin/RegisterAdmin.vue'),
      meta: {
        pageTitle: 'Register New Admin',
        breadcrumb: [
          {
            text: 'Register New Admin',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'admin',
      },
    },
    {
      path: '/admin/index',
      name: 'Admins-Index',
      component: () =>
        import('@/views/Administration/AdminIndex/AdminsIndex.vue'),
      meta: {
        pageTitle: 'Admins Index',
        breadcrumb: [
          {
            text: 'Admins Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'admin',
      },
    },
    {
      path: '/admin/trashed',
      name: 'Admins-Trashed',
      component: () =>
        import('@/views/Administration/AdminIndex/AdminTrashedData.vue'),
      meta: {
        pageTitle: 'Admins Trashed',
        breadcrumb: [
          {
            text: 'Admins Trashed',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'admin',
      },
    },
    {
      path: '/admin/profile',
      name: 'Admin-Profile',
      component: () =>
        import('@/views/Administration/AdminProfile/AdminProfile.vue'),
      meta: {
        pageTitle: 'Admin Profile',
        breadcrumb: [
          {
            text: 'Admin Profile',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin-update/profile',
      name: 'Admin-EditeProfile',
      component: () =>
        import('@/views/Administration/AdminProfile/AdminEditProfile.vue'),
      meta: {
        pageTitle: 'Admin Edit Profile',
        breadcrumb: [
          {
            text: 'Admin Edit Profile',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/update/password',
      name: 'Admin-Change-Password',
      props: true,
      component: () =>
        import('@/views/Administration/AdminProfile/ChangePassword.vue'),
      meta: {
        pageTitle: 'Change Password',
        breadcrumb: [
          {
            text: 'Change Password',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/admin/show/:id',
      name: 'Admin-show',
      props: true,
      component: () =>
        import('@/views/Administration/AdminProfile/ShowProfileById.vue'),
      meta: {
        pageTitle: 'Admin',
        breadcrumb: [
          {
            text: 'Admin',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'admin',
      },
    },
    {
      path: '/admin/edite/:id',
      name: 'Admin-Update',
      props: true,
      component: () =>
        import('@/views/Administration/AdminProfile/UpdateAdminById.vue'),
      meta: {
        pageTitle: 'Edite',
        breadcrumb: [
          {
            text: 'Edite',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'admin',
      },
    },

    // *===============================================---*
    // *--------- Roles ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/Roles',
      name: 'Roles-list',
      component: () => import('@/views/Roles/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'role',
      },
    },
    {
      path: '/Add-Role',
      name: 'Add-Role',
      component: () => import('@/views/Roles/AddRole.vue'),
      meta: {
        pageTitle: 'Add Role',
        breadcrumb: [
          {
            text: 'Add Role',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'role',
      },
    },
    {
      path: '/AssignRole/:id',
      name: 'AssignRole',
      props: true,
      component: () => import('@/views/Roles/AssignR&P.vue'),
      meta: {
        pageTitle: 'Assign Role',
        breadcrumb: [
          {
            text: 'Assign Role',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'role',
      },
    },
    {
      path: '/RevokeRole/:id',
      name: 'RevokeRole',
      props: true,
      component: () => import('@/views/Roles/RevokeR&P.vue'),
      meta: {
        pageTitle: 'Revoke Role',
        breadcrumb: [
          {
            text: 'Revoke Role',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'role',
      },
    },

    // *===============================================---*
    // *--------- Permissions ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/Permissions',
      name: 'Permissions-list',
      component: () => import('@/views/Permissions/Permissions.vue'),
      meta: {
        pageTitle: 'Permissions',
        breadcrumb: [
          {
            text: 'Permissions',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'permission',
      },
    },
    {
      path: '/Add-Permission',
      name: 'Add-Permission',
      component: () => import('@/views/Permissions/AddPermission.vue'),
      meta: {
        pageTitle: 'Add Permission',
        breadcrumb: [
          {
            text: 'Add Permission',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'permission',
      },
    },

    // *===============================================---*
    // *--------- Categories ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/Categories',
      name: 'Categories-list',
      component: () => import('@/views/Categories/CategoriesIndex.vue'),
      meta: {
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'category',
      },
    },
    {
      path: '/Add/Category',
      name: 'Add-Category',
      component: () => import('@/views/Categories/AddCategory.vue'),
      meta: {
        pageTitle: 'Add Category',
        breadcrumb: [
          {
            text: 'Add Category',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'create',
        per2: 'category',
      },
    },
    {
      path: '/category/:id/attributes',
      name: 'Manage-Category-Attributes',
      component: () => import('@/views/Categories/ManageCategoryAttributes.vue'),
      meta: {
        pageTitle: 'Manage Category Attributes',
        breadcrumb: [
          {
            text: 'Manage Category Attributes',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'update',
        per2: 'category',
      },
    },
    {
      path: '/Update-Category/:id',
      name: 'Update-Category',
      props: true,
      component: () => import('@/views/Categories/UpdateCategory.vue'),
      meta: {
        pageTitle: 'Update Category',
        breadcrumb: [
          {
            text: 'Update Category',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'update',
        per2: 'category',
      },
    },
    {
      path: '/Show-Category/:id',
      name: 'Show-Category',
      props: true,
      component: () => import('@/views/Categories/ShowCategory.vue'),
      meta: {
        pageTitle: 'Show Category',
        breadcrumb: [
          {
            text: 'Show Category',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'category',
      },
    },
    {
      path: '/Trashed/Categories',
      name: 'Trashed-Categories',
      component: () => import('@/views/Categories/CatArchivedData.vue'),
      meta: {
        pageTitle: 'Trahsed Categories',
        breadcrumb: [
          {
            text: 'Trahsed Categories',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'category',
      },
    },
    {
      path: '/category/add/description/:id',
      name: 'Category-Add-Trans',
      props: true,
      component: () => import('@/views/Categories/AddTrans.vue'),
      meta: {
        pageTitle: 'Category Add Description',
        breadcrumb: [
          {
            text: 'Category Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'category',
      },
    },
    {
      path: '/category/update/description/:id',
      name: 'Category-Update-Trans',
      props: true,
      component: () => import('@/views/Categories/UpdateTrans.vue'),
      meta: {
        pageTitle: 'Category Update Description',
        breadcrumb: [
          {
            text: 'Category Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'category',
      },
    },

    // *===============================================---*
    // *--------- Brands ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/Brands',
      name: 'Brands-list',
      component: () => import('@/views/Brands/BrandsIndex.vue'),
      meta: {
        pageTitle: 'Brands',
        breadcrumb: [
          {
            text: 'Brands',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'brand',
      },
    },
    {
      path: '/Add/Brand',
      name: 'Add-Brand',
      component: () => import('@/views/Brands/AddBrand.vue'),
      meta: {
        pageTitle: 'Add Brand',
        breadcrumb: [
          {
            text: 'Add Brand',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'create',
        per2: 'brand',
      },
    },
    {
      path: '/SortBrands',
      name: 'Sort-Brands',
      component: () => import('@/views/Brands/SortBrands.vue'),
      meta: {
        pageTitle: 'Sort Brands',
        breadcrumb: [
          {
            text: 'Add Brand',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'create',
        per2: 'brand',
      },
    },
    {
      path: '/Update-Brand/:id',
      name: 'Update-Brand',
      props: true,
      component: () => import('@/views/Brands/UpdateBrand.vue'),
      meta: {
        pageTitle: 'Update Brand',
        breadcrumb: [
          {
            text: 'Update Brand',
            active: true,
          },
        ],
        requiresAuth: true,

        per: 'update',
        per2: 'brand',
      },
    },
    {
      path: '/Show-Brand/:id',
      name: 'Show-Brand',
      props: true,
      component: () => import('@/views/Brands/ShowBrand.vue'),
      meta: {
        pageTitle: 'Show Brand',
        breadcrumb: [
          {
            text: 'Show Brand',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'brand',
      },
    },
    {
      path: '/Trashed/Brands',
      name: 'Trashed-Brands',
      component: () => import('@/views/Brands/BrandArchivedData.vue'),
      meta: {
        pageTitle: 'Trahsed Brands',
        breadcrumb: [
          {
            text: 'Trahsed Brands',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'brand',
      },
    },
    {
      path: '/brand/add/description/:id',
      name: 'Brand-Add-Trans',
      props: true,
      component: () => import('@/views/Brands/AddTrans.vue'),
      meta: {
        pageTitle: 'Brand Add Description',
        breadcrumb: [
          {
            text: 'Brand Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'brand',
      },
    },
    {
      path: '/brand/update/description/:id',
      name: 'Brand-Update-Trans',
      props: true,
      component: () => import('@/views/Brands/UpdateTrans.vue'),
      meta: {
        pageTitle: 'Brand Update Description',
        breadcrumb: [
          {
            text: 'Brand Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'brand',
      },
    },

    // *===============================================---*
    // *--------- Tags ---- ---------------------------------------*
    // *===============================================---*

    {
      path: '/Tags',
      name: 'Tags-list',
      component: () => import('@/views/Tags/TagsIndex.vue'),
      meta: {
        pageTitle: 'Tags',
        breadcrumb: [
          {
            text: 'Tags',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'tag',
      },
    },
    {
      path: '/Add-Tag',
      name: 'Add-Tag',
      component: () => import('@/views/Tags/AddTag.vue'),
      meta: {
        pageTitle: 'Add Tag',
        breadcrumb: [
          {
            text: 'Add Tag',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'tag',
      },
    },

    // *===============================================---*
    // *--------- Terms ---- ---------------------------------------*
    // *===============================================---*

    {
      path: '/Terms',
      name: 'Terms-list',
      component: () => import('@/views/Terms/TermsIndex.vue'),
      meta: {
        pageTitle: 'Terms',
        breadcrumb: [
          {
            text: 'Terms',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'term',
      },
    },
    {
      path: '/Add-Term',
      name: 'Add-Term',
      component: () => import('@/views/Terms/AddTerm.vue'),
      meta: {
        pageTitle: 'Add Term',
        breadcrumb: [
          {
            text: 'Add Term',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'term',
      },
    },
    {
      path: '/Trashed/Terms',
      name: 'Trashed-Terms',
      component: () => import('@/views/Terms/TermsArchivedData.vue'),
      meta: {
        pageTitle: 'Trahsed Terms',
        breadcrumb: [
          {
            text: 'Trahsed Terms',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'term',
      },
    },
    // *===============================================---*
    // *--------- Currencies --------------------------------------------*
    // *===============================================---*
    {
      path: '/Currencies',
      name: 'Currencies',
      component: () => import('@/views/Currencies/CurrenciesIndex.vue'),
      meta: {
        pageTitle: 'Currencies',
        breadcrumb: [
          {
            text: 'Currencies',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'currency',
      },
    },
    {
      path: '/AddCurrencies',
      name: 'AddCurrencies',
      component: () => import('@/views/Currencies/AddCurrencies.vue'),
      meta: {
        pageTitle: 'Add Currency',
        breadcrumb: [
          {
            text: 'Add Currency',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'currency',
      },
    },
    {
      path: '/Trashed/Currencies',
      name: 'Trashed-Currencies',
      component: () => import('@/views/Currencies/CurrenciesArchivedData.vue'),
      meta: {
        pageTitle: 'Trashed Currency',
        breadcrumb: [
          {
            text: 'Trashed Currency',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'currency',
      },
    },

    // *===============================================---*
    // *--------- Phone Guide --------------------------------------------*
    // *===============================================---*
    {
      path: '/PhoneGuide',
      name: 'PhoneGuide',
      component: () => import('@/views/PhoneGuide/PhoneGuideIndex.vue'),
      meta: {
        pageTitle: 'Phones Guide',
        breadcrumb: [
          {
            text: 'Phones Guide',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'phone_guide',
      },
    },
    {
      path: '/PhoneGuideEcommerce',
      name: 'PhoneGuideEcommerce',
      component: () => import('@/views/PhoneGuide/phoneGuideEcommIndex.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Phones Guide',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Phones Guide',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'phone_guide',
      },
    },
    {
      path: '/Trashed/PhoneGuide',
      name: 'Trashed-PhoneGuide',
      component: () => import('@/views/PhoneGuide/PhoneGuideArchivedData.vue'),
      meta: {
        pageTitle: 'Trashed Phone Guide',
        breadcrumb: [
          {
            text: 'Trashed Phone Guide',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'phone_guide',
      },
    },
    {
      path: '/CreateItem',
      name: 'CreateItem',
      component: () => import('@/views/PhoneGuide/CreateItem.vue'),
      meta: {
        pageTitle: 'Create Item',
        breadcrumb: [
          {
            text: 'Create Item',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/duplicate/:id',
      name: 'Duplicate',
      props: true,
      component: () => import('@/views/PhoneGuide/Duplicate.vue'),
      meta: {
        pageTitle: 'Duplicate',
        breadcrumb: [
          {
            text: 'Duplicate',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/ShowPhoneGuide/:id',
      name: 'ShowPhoneGuide',
      props: true,
      component: () => import('@/views/PhoneGuide/ShowPhoneGuide.vue'),
      meta: {
        pageTitle: 'Phone Guide',
        breadcrumb: [
          {
            text: 'Phone Guide',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'phone_guide',
      },
    },
    {
      path: '/UpdateMainData/:id',
      name: 'UpdateMainData',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemMainData.vue'),
      meta: {
        pageTitle: 'Update Main Data',
        breadcrumb: [
          {
            text: 'Update Main Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/UpdateItemCovers/:id',
      name: 'UpdateItemCovers',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemCovers.vue'),
      meta: {
        pageTitle: 'Update Item Covers',
        breadcrumb: [
          {
            text: 'Update Item Covers',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/UpdateTags/:id',
      name: 'UpdateTags',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemTags.vue'),
      meta: {
        pageTitle: 'Update Tags',
        breadcrumb: [
          {
            text: 'Update Tags',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/add/specify/:id',
      name: 'Add-Specify',
      props: true,
      component: () => import('@/views/PhoneGuide/AddNew/AddNewTax'),
      meta: {
        pageTitle: 'Add Specify',
        breadcrumb: [
          {
            text: 'Add Specify',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/addNewPrice/:id',
      name: 'addNewPrice',
      props: true,
      component: () => import('@/views/PhoneGuide/AddNew/addNewPrice'),
      meta: {
        pageTitle: 'Add New Price',
        breadcrumb: [
          {
            text: 'Add New Price',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/UpdatePrice/:id',
      name: 'UpdatePrice',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemPrice'),
      meta: {
        pageTitle: 'Update Price',
        breadcrumb: [
          {
            text: 'Update Price',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/addNewTranslation/:id',
      name: 'addNewTranslation',
      props: true,
      component: () => import('@/views/PhoneGuide/AddNew/addNewTranslation'),
      meta: {
        pageTitle: 'Add New Translation',
        breadcrumb: [
          {
            text: 'Add New Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/UpdateTranslation/:id',
      name: 'UpdateTranslation',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemTranslation'),
      meta: {
        pageTitle: 'Update Translation',
        breadcrumb: [
          {
            text: 'Update Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/AddNewImage/:id',
      name: 'AddNewImage',
      props: true,
      component: () => import('@/views/PhoneGuide/AddNew/AddNewImage'),
      meta: {
        pageTitle: 'Add Image',
        breadcrumb: [
          {
            text: 'Add Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'phone_guide',
      },
    },
    {
      path: '/EditTaxonomies/:id',
      name: 'AddImage',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/EditItemTaxonomy'),
      meta: {
        pageTitle: 'Edit Taxonomies',
        breadcrumb: [
          {
            text: 'Edit Taxonomies',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/ShowPhoneGuide/:id/RevokeValue/:attr_id',
      name: 'RevokeValue',

      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/RevokeValue'),
      meta: {
        pageTitle: 'Revoke Value',
        breadcrumb: [
          {
            text: 'Revoke Value',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/ShowPhoneGuide/:id/AssignValue/:attr_id',
      name: 'AssignValue',

      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/AssignValue'),
      meta: {
        pageTitle: 'Assign Value',
        breadcrumb: [
          {
            text: 'Assign Value',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/EditImage',
      name: 'EditImage',
      props: true,
      component: () =>
        import('@/views/PhoneGuide/UpdatePhoneGuide/UpdateItemImage'),
      meta: {
        pageTitle: 'Edit Image',
        breadcrumb: [
          {
            text: 'Edit Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'phone_guide',
      },
    },
    {
      path: '/logins',
      name: 'Items-Parents',
      props: true,
      component: () => import('@/views/PhoneGuide/Parents/Index'),
      meta: {
        pageTitle: 'Items Parent',
        breadcrumb: [
          {
            text: 'Items Parent',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'phone_guide',
      },
    },

    {
      path: '*',
      name: '404',
      component: () => import('@/views/error/404NotFound.vue'),

      meta: {
        layout: 'full',
      },
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      // beforeEnter: (to, from, next) => {
      //   if (store.getters.isAuthenticated) {
      //     next('/')
      //   } else {
      //     next()
      //   }
      // },
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/ServerError',
      name: 'ServerError',
      component: () => import('@/views/error/ServerError.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/ServerError',
      redirect: 'ServerError',
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/Notifications.vue'),
      meta: {
        pageTitle: 'Notifications',
        breadcrumb: [
          {
            text: 'Notifications Page',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'notifications',
      },
    },

    // *===============================================---*
    // *--------- Orders  --------------------------------------------*
    // *===============================================---*
    {
      path: '/orders/orderStatus',
      name: 'orderStatus',
      component: () => import('@/views/Orders/orderStatus.vue'),
      meta: {
        pageTitle: 'Order Status',
        breadcrumb: [
          {
            text: 'Order Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order-status',
      },
    },
    {
      path: '/orders/updateorderStatus/:id',
      name: 'updateorderStatus',
      props: true,
      component: () => import('@/views/Orders/updateorderStatus.vue'),
      meta: {
        pageTitle: 'Update Order Status',
        breadcrumb: [
          {
            text: 'Update Order Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'update-order-status',
      },
    },
    {
      path: '/orders/Add/Status',
      name: 'addorderStatus',
      component: () => import('@/views/Orders/addorderStatus.vue'),
      meta: {
        pageTitle: 'Add Order Status',
        breadcrumb: [
          {
            text: 'Add Order Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'order-status',
      },
    },
    {
      path: '/orders/show/invoice/:id',
      name: 'invoice',
      props: true,
      component: () => import('@/views/Orders/invoice.vue'),
      meta: {
        requiresAuth: true,
        per: 'show-order-invoice',
        per2: 'order',
      },
    },
    {
      path: '/orders/show/:id',
      name: 'Orders-Show',
      props: true,
      component: () => import('@/views/Orders/ShowOrder.vue'),
      meta: {
        pageTitle: 'Show Order',
        breadcrumb: [
          {
            text: 'Show Order',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    {
      path: '/orders/show/:id/e-contract',
      name: 'Econtract-Order',
      props: true,
      component: () => import('@/views/Orders/EContractV2Order.vue'),
      meta: {
        pageTitle: 'Econtract',
        breadcrumb: [
          {
            text: 'Econtract',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    {
      path: '/orders/show/:id/e-contract/old',
      name: 'Econtract-Order-Old',
      props: true,
      component: () => import('@/views/Orders/EContractInStoreOrder.vue'),
      meta: {
        pageTitle: 'Econtract',
        breadcrumb: [
          {
            text: 'Econtract',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    {
      path: '/orders/update/products/:id',
      name: 'Orders-update-products',
      props: true,
      component: () => import('@/views/Orders/UpdateOrderProducts.vue'),
      meta: {
        pageTitle: 'Update Order Products',
        breadcrumb: [
          {
            text: 'Update Order Products',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'order',
      },
    },
    {
      path: '/orders/OrderIndex/',
      name: 'OrderIndex',
      component: () => import('@/views/Orders/OrderIndex.vue'),
      meta: {
        pageTitle: 'OrderIndex',
        breadcrumb: [
          {
            text: 'OrderIndex',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    {
      path: '/orders/index/completed',
      name: 'OrderCompletedIndex',
      component: () => import('@/views/Orders/OrderCompletedIndex.vue'),
      meta: {
        pageTitle: 'OrderCompletedIndex',
        breadcrumb: [
          {
            text: 'OrderCompletedIndex',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    {
      path: '/orders/Trashed/',
      name: 'OrderTrashed',
      component: () => import('@/views/Orders/OrdersTrashedData.vue'),
      meta: {
        pageTitle: 'OrderTrashed',
        breadcrumb: [
          {
            text: 'OrderTrashed',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'order',
      },
    },
    {
      path: '/orders/Trashed/Index/Status/',
      name: 'Order-Trashed-Status',
      component: () => import('@/views/Orders/TrashedIndexStatus.vue'),
      meta: {
        pageTitle: 'Trashed Order Status Index',
        breadcrumb: [
          {
            text: 'Trashed Order Status Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'order-status',
      },
    },
    {
      path: '/orders/Create',
      name: 'CreateOrder',
      component: () => import('@/views/Orders/CreateOrder.vue'),
      meta: {
        pageTitle: 'Create Order',
        breadcrumb: [
          {
            text: 'Create Order',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'order',
      },
    },
    {
      path: '/orders/Update/:id',
      name: 'UpdateOrder',
      props: true,
      component: () => import('@/views/Orders/UpdateOrder.vue'),
      meta: {
        pageTitle: 'Update Order',
        breadcrumb: [
          {
            text: 'Update Order',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-order',
        per2: 'order',
      },
    },
    {
      path: '/orders/Update/Status/:id',
      name: 'UpdateOrderStatus',
      props: true,
      component: () => import('@/views/Orders/ChangeOrderStatus.vue'),
      meta: {
        pageTitle: 'Update Order Status',
        breadcrumb: [
          {
            text: 'Update Order Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-order-status',
        per2: 'order',
      },
    },
    {
      path: '/orders/show/compare',
      name: 'OrderShowCompareReports',
      props: true,
      component: () => import('@/views/Orders/CompareReports.vue'),
      meta: {
        pageTitle: 'Comparing Reports',
        breadcrumb: [
          {
            text: 'Comparing Reports',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'order',
      },
    },
    // *===============================================---*
    // *--------- Payment Session  --------------------------------------------*
    // *===============================================---*

    {
      path: '/paymentSessions/realtime/show/:id',
      name: 'paymentSession-realtime-show',
      props: true,
      component: () => import('@/views/PaymentSessions/paymentSessionRealTimeView.vue'),
      meta: {
        pageTitle: 'Show Payment Session RealTime',
        breadcrumb: [
          {
            text: 'Show Payment Session RealTime',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment-session',
      },
    },
    {
      path: '/paymentSessions/show/:id',
      name: 'paymentSession-show',
      props: true,
      component: () => import('@/views/PaymentSessions/show.vue'),
      meta: {
        pageTitle: 'Show Payment Session',
        breadcrumb: [
          {
            text: 'Show  Payment Session',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment-session',
      },
    },
    {
      path: '/paymentSessions/show/:id/e-contract',
      name: 'paymentSession-eContract',
      props: true,
      component: () => import('@/views/PaymentSessions/paymentSessionEContract.vue'),
      meta: {
        pageTitle: 'Show Payment Session EContract',
        breadcrumb: [
          {
            text: 'Show  Payment Session EContract',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-eContract',
        per2: 'payment-session',
      },
    },
    {
      path: '/paymentSessions',
      name: 'paymentSession-index',
      component: () => import('@/views/PaymentSessions/index.vue'),
      meta: {
        pageTitle: 'Payment Session List',
        breadcrumb: [
          {
            text: 'Payment Session List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment-session',
      },
    },
    {
      path: '/paymentSessions/completed',
      name: 'paymentSession-completed-index',
      component: () => import('@/views/PaymentSessions/completedIndex.vue'),
      meta: {
        pageTitle: 'Payment Session Completed List',
        breadcrumb: [
          {
            text: 'Payment Session Completed List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment-session',
      },
    },
    {
      path: '/paymentSessions/trashed',
      name: 'paymentSession-trashed-index',
      component: () => import('@/views/PaymentSessions/indexTrashed.vue'),
      meta: {
        pageTitle: 'paymentSession Trashed List',
        breadcrumb: [
          {
            text: 'paymentSession Trashed List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'payment-session',
      },
    },
    // *===============================================---*
    // *--------- Bidding Requests --------------------------------------------*
    // *===============================================---*

    {
      path: '/biddings-requests/show/:id',
      name: 'biddings-request-show',
      props: true,
      component: () => import('@/views/BiddingRequests/show.vue'),
      meta: {
        pageTitle: 'Show Bidding Requests',
        breadcrumb: [
          {
            text: 'Show  Bidding Requests',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'bidding',
      },
    },
    {
      path: '/biddings-requests',
      name: 'biddings-request-index',
      component: () => import('@/views/BiddingRequests/index.vue'),
      meta: {
        pageTitle: 'Bidding Requests List',
        breadcrumb: [
          {
            text: 'Bidding Requests List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'bidding',
      },
    },
    {
      path: '/biddings-requests/archived/',
      name: 'biddings-request-archived-index',
      component: () => import('@/views/BiddingRequests/indexTrashed.vue'),
      meta: {
        pageTitle: 'Bidding Requests Archived List',
        breadcrumb: [
          {
            text: 'Bidding Requests Archived List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'bidding',
      },
    },
   
      // *===============================================---*
    // *--------- AirWayBill  --------------------------------------------*
    // *===============================================---*
    {
      path: '/AirWayBill/index',
      name: 'IndexAirWayBill',
      component: () => import('@/views/AirWayBill/Index.vue'),
      meta: {
        pageTitle: 'Index AirWayBill',
        breadcrumb: [
          {
            text: 'Index AirWayBill',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'air-way-bill',
      },
    },
    {
      path: '/AirWayBill/create',
      name: 'CreateAirWayBill',
      component: () => import('@/views/AirWayBill/CreateAirWayBill.vue'),
      meta: {
        pageTitle: 'Create AirWayBill',
        breadcrumb: [
          {
            text: 'Create AirWayBill',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'create-air-way-bill',
      },
    },
    {
      path: '/AirWayBill/show/:id',
      name: 'AirWayBill',
      component: () => import('@/views/AirWayBill/Show.vue'),
      meta: {
        pageTitle: 'Show AirWayBill',
        breadcrumb: [
          {
            text: 'Show AirWayBill',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'air-way-bill',
      },
    },

    // *===============================================---*
    // *--------- Vendor --------------------------------------------*
    // *===============================================---*
    {
      path: '/Vendor/Index',
      name: 'Vendor-Index',
      component: () => import('@/views/Vendor/vendors.vue'),
      meta: {
        pageTitle: 'Sellers List',
        breadcrumb: [
          {
            text: 'Sellers List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor',
      },
    },
    {
      path: '/activeVendor/Index',
      name: 'Active-Vendor-Index',
      component: () => import('@/views/Vendor/ActiveVendors.vue'),
      meta: {
        pageTitle: 'Active Sellers List',
        breadcrumb: [
          {
            text: 'Active Sellers List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Index',
      name: 'Seller-Index',
      component: () => import('@/views/Vendor/VendorIndex.vue'),
      meta: {
        pageTitle: 'Dealers List',
        breadcrumb: [
          {
            text: 'Dealers List',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Index/Trashed',
      name: 'Seller-Index-Trashed',
      component: () => import('@/views/Vendor/VendorTrashed.vue'),
      meta: {
        pageTitle: 'Dealers Archived Data',
        breadcrumb: [
          {
            text: 'Dealers Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Add',
      name: 'Seller-Add',
      component: () => import('@/views/Vendor/AddVendor.vue'),
      meta: {
        pageTitle: 'Add Dealer',
        breadcrumb: [
          {
            text: 'Add Dealer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Show/:id',
      name: 'Seller-Show',
      props: true,
      component: () => import('@/views/Vendor/ShowVendor.vue'),
      meta: {
        pageTitle: 'Show Dealer',
        breadcrumb: [
          {
            text: 'Show Dealer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Update/:id',
      name: 'Seller-Update',
      props: true,
      component: () => import('@/views/Vendor/UpdateVendor.vue'),
      meta: {
        pageTitle: 'Update Dealer',
        breadcrumb: [
          {
            text: 'Update Dealer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-vendor-data',
        per2: 'vendor',
      },
    },
    {
      path: '/Vendor/Update/Status/:id',
      name: 'Vendor-Seller-Update-Status',
      props: true,
      component: () => import('@/views/Vendor/UpdateStatusVendor.vue'),
      meta: {
        pageTitle: 'Update Dealer Status',
        breadcrumb: [
          {
            text: 'Update Dealer Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-vendor-status',
        per2: 'vendor',
      },
    },
    // Agency
    {
      path: '/Vendor/Agency/Update/:id',
      name: 'Agency-Update',
      props: true,
      component: () => import('@/views/Vendor/Agency/UpdateVendor.vue'),
      meta: {
        pageTitle: 'Update Agency',
        breadcrumb: [
          {
            text: 'Update Agency',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-vendor-data',
        per2: 'vendor',
      },
    },
 
    // {
    //   path: '/Vendor/Update/Agency/Status/:id',
    //   name: 'Vendor-Agency-Update-Status',
    //   props: true,
    //   component: () => import('@/views/Vendor/Agency/UpdateStatusVendor.vue'),
    //   meta: {
    //     pageTitle: 'Update Agency Status',
    //     breadcrumb: [
    //       {
    //         text: 'Update Agency Status',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'update-vendor-status',
    //     per2: 'vendor',
    //   },
    // },
    // {
    //   path: '/Vendor/Saller/AddMobileNumbers/:id',
    //   name: 'Seller-AddMobiles',
    //   component: () => import('@/views/Vendor/AddNewMobile.vue'),
    //   meta: {
    //     pageTitle: 'Add New Mobiles',
    //     breadcrumb: [
    //       {
    //         text: 'Add New Mobiles',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'create',
    //     per2: 'vendor',
    //   },
    // },

    // {
    //   path: '/Vendor/Agency/Index',
    //   name: 'Agency-Index',
    //   component: () => import('@/views/Vendor/Agency/VendorIndex.vue'),
    //   meta: {
    //     pageTitle: 'Agency Index',
    //     breadcrumb: [
    //       {
    //         text: 'Agency Index',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show',
    //     per2: 'vendor',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Index/Trashed',
    //   name: 'Agency-Index-Trashed',
    //   component: () => import('@/views/Vendor/Agency/VendorTrashed.vue'),
    //   meta: {
    //     pageTitle: 'Agency Archived Data',
    //     breadcrumb: [
    //       {
    //         text: 'Agency Archived Data',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show-trashed',
    //     per2: 'vendor',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Add',
    //   name: 'Agency-Add',
    //   component: () => import('@/views/Vendor/Agency/AddVendor.vue'),
    //   meta: {
    //     pageTitle: 'Add Agency',
    //     breadcrumb: [
    //       {
    //         text: 'Add Agency',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'create',
    //     per2: 'vendor',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Show/:id',
    //   name: 'Agency-Show',
    //   props: true,
    //   component: () => import('@/views/Vendor/Agency/ShowVendor.vue'),
    //   meta: {
    //     pageTitle: 'Show Agency',
    //     breadcrumb: [
    //       {
    //         text: 'Show Agency',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show',
    //     per2: 'vendor',
    //   },
    // },
    // {
    //     path: '/Vendor/Agency/Update:id',
    //     name: 'Agency-Update',
    //     props: true,
    //     component: () =>
    //         import ('@/views/Vendor/Agency/UpdateVendor.vue'),
    //     meta: {
    //         pageTitle: 'Update Agency',
    //         breadcrumb: [{
    //             text: 'Update Agency',
    //             active: true,
    //         }, ],
    //         requiresAuth: true,
    //         per: 'update',
    //         per2: 'vendor'
    //     },
    // },
    // {
    //   path: '/Vendor/Agency/AddMobileNumbers/:id',
    //   name: 'Agency-AddMobiles',
    //   component: () => import('@/views/Vendor/Agency/AddNewMobile.vue'),
    //   meta: {
    //     pageTitle: 'Add New Mobiles',
    //     breadcrumb: [
    //       {
    //         text: 'Add New Mobiles',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'create',
    //     per2: 'vendor',
    //   },
    // },

    {
      path: '/Vendor/Status',
      name: 'vendorStatus',
      component: () => import('@/views/Vendor/vendorStatus.vue'),
      meta: {
        pageTitle: 'Sellers Status',
        breadcrumb: [
          {
            text: 'Sellers Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor_status',
      },
    },
    {
      path: '/Vendor/addStatus',
      name: 'addStatus',
      component: () => import('@/views/Vendor/addStatus.vue'),
      meta: {
        pageTitle: 'Add Seller Status',
        breadcrumb: [
          {
            text: 'Add Seller Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'vendor_status',
      },
    },
    {
      path: '/Vendor/updateVendorStatus/:id',
      name: 'updateVendorStatus',
      props: true,
      component: () => import('@/views/Vendor/updateVendorStatus.vue'),
      meta: {
        pageTitle: 'Update Seller Status',
        breadcrumb: [
          {
            text: 'Update Seller Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'vendor_status',
      },
    },

    {
      path: '/vendor/wallets/index',
      name: 'Vendor-Wallets-Index',
      component: () => import('@/views/Vendor/Wallets/index.vue'),
      meta: {
        pageTitle: 'Sellers Wallets Summary',
        breadcrumb: [
          {
            text: 'Sellers Wallets Summary',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor_wallet',
      },
    },
    {
      path: '/vendor/wallet/show/:id',
      name: 'Vendor-Wallets-Show',
      component: () => import('@/views/Vendor/Wallets/show.vue'),
      meta: {
        pageTitle: 'Seller Wallet',
        breadcrumb: [
          {
            text: 'Seller Wallet',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor_wallet',
      },
    },
    // {
    //   path: '/vendor/commissions/edite/:id',
    //   name: 'Vendor-Commissions-edite',
    //   component: () => import('@/views/Vendor/Commissions/EditCommissions.vue'),
    //   meta: {
    //     pageTitle: 'Seller Commissions edite',
    //     breadcrumb: [
    //       {
    //         text: 'Seller Commissions edite',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'update',
    //     per2: 'vendor_wallet',
    //   },
    // },

    {
      path: '/Vendor/Pricing/:id',
      name: 'Vendor-Price-Rate',
      component: () => import('@/views/Vendor/VendorPriceRate/index.vue'),
      meta: {
        pageTitle: 'Margin Fees',
        breadcrumb: [
          {
            text: 'Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'vendor_price_rate',
      },
    },
    {
      path: '/Vendor/Pricing/Trashed',
      name: 'Vendor-Price-Rate-Trashed',
      component: () => import('@/views/Vendor/VendorPriceRate/trashed.vue'),
      meta: {
        pageTitle: 'Margin Fees Archived Data',
        breadcrumb: [
          {
            text: 'Margin Fees Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'vendor_price_rate',
      },
    },
    {
      path: '/Vendor/Pricing/Add/:id',
      name: 'Vendor-Price-Rate-Add',
      component: () => import('@/views/Vendor/VendorPriceRate/add.vue'),
      meta: {
        pageTitle: 'Add Margin Fees',
        breadcrumb: [
          {
            text: 'Add Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'vendor_price_rate',
      },
    },
    {
      path: '/Vendor/Pricing/update/:id',
      props: true,
      name: 'Vendor-Price-Rate-Update',
      component: () => import('@/views/Vendor/VendorPriceRate/update.vue'),
      meta: {
        pageTitle: 'Update Margin Fees',
        breadcrumb: [
          {
            text: 'Update Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'vendor_price_rate',
      },
    },

    // {
    //   path: '/Vendor/Agency/Pricing/:id',
    //   name: 'Agency-Price-Rate',
    //   component: () =>
    //     import('@/views/Vendor/Agency/VendorPriceRate/index.vue'),
    //   meta: {
    //     pageTitle: 'Margin Fees',
    //     breadcrumb: [
    //       {
    //         text: 'Margin Fees',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show',
    //     per2: 'vendor_price_rate',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Pricing/Trashed',
    //   name: 'Agency-Price-Rate-Trashed',
    //   component: () =>
    //     import('@/views/Vendor/Agency/VendorPriceRate/trashed.vue'),
    //   meta: {
    //     pageTitle: 'Margin Fees Archived Data',
    //     breadcrumb: [
    //       {
    //         text: 'Margin Fees Archived Data',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show-trashed',
    //     per2: 'vendor_price_rate',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Pricing/Add/:id',
    //   name: 'Agency-Price-Rate-Add',
    //   component: () => import('@/views/Vendor/Agency/VendorPriceRate/add.vue'),
    //   meta: {
    //     pageTitle: 'Add Margin Fees',
    //     breadcrumb: [
    //       {
    //         text: 'Add Margin Fees',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'create',
    //     per2: 'vendor_price_rate',
    //   },
    // },
    // {
    //   path: '/Vendor/Agency/Pricing/update/:id',
    //   props: true,
    //   name: 'Agency-Price-Rate-Update',
    //   component: () =>
    //     import('@/views/Vendor/Agency/VendorPriceRate/update.vue'),
    //   meta: {
    //     pageTitle: 'Update Margin Fees',
    //     breadcrumb: [
    //       {
    //         text: 'Update Margin Fees',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'update',
    //     per2: 'vendor_price_rate',
    //   },
    // },

    // *===============================================---*
    // *---------General  Product --------------------------------------------*
    // *===============================================---*
    {
      path: '/Product/Add',
      name: 'AddGeneralProduct',
      component: () => import('@/views/GeneralProduct/AddGeneralProduct.vue'),
      meta: {
        pageTitle: 'AddProduct',
        breadcrumb: [
          {
            text: 'AddProduct',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },

    // *===============================================---*
    // *---------Add  Product For Specific Category--------------------------------------------*
    // *===============================================---*
    {
      path: '/category/:id/:channelType/:productType/add',
      name: 'AddGeneralProductData',
      component: () => import('@/views/GeneralProduct/AddGeneralProducData.vue'),
      meta: {
        pageTitle: 'AddProduct',
        breadcrumb: [
          {
            text: 'AddProduct',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    // {
    //   path: '/category/:id/:channelType/:productType/add',
    //   name: 'AddIGeneralnstoreProduct',
    //   component: () => import('@/views/GeneralProduct/AddGeneralProducData.vue'),
    //   meta: {
    //     pageTitle: 'AddProduct',
    //     breadcrumb: [
    //       {
    //         text: 'AddProduct',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'create',
    //     per2: 'product',
    //   },
    // },

    // *===============================================---*
    // *--------- Product --------------------------------------------*
    // *===============================================---*
    {
      path: '/categories/:id/Used/Product/Add',
      name: 'AddProduct',
      component: () => import('@/views/Product/AddProductWithOffer.vue'),
      meta: {
        pageTitle: 'AddProduct',
        breadcrumb: [
          {
            text: 'AddProduct',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/categories/:id/Used/In-Store/Product/Add',
      name: 'AddProduct-InStore',
      component: () => import('@/views/Product/AddProductInStore.vue'),
      meta: {
        pageTitle: 'AddProduct',
        breadcrumb: [
          {
            text: 'AddProduct',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/categories/:id/Used/Unactive/Product/Add',
      name: 'AddUnactiveProduct',
      component: () => import('@/views/Product/AddUnActiveProduct.vue'),
      meta: {
        pageTitle: 'Add Unactive Product',
        breadcrumb: [
          {
            text: 'Add Unactive Product',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/Index',
      name: 'ProductIndex',
      component: () => import('@/views/Product/ProductIndex.vue'),
      meta: {
        pageTitle: 'Product Index',
        breadcrumb: [
          {
            text: 'Product Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },

    {
      path: '/Used/Product/Card/Index',
      name: 'ProductCardIndex',
      component: () => import('@/views/Product/ProductEcommIndex.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Products Card Index',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Products Card Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/Family/Index',
      name: 'ProductFamilyIndex',
      component: () => import('@/views/Product/FamilyProducts.vue'),
      meta: {
        pageTitle: 'Products Family Index',
        breadcrumb: [
          {
            text: 'Products Family Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/trashed/Index',
      name: 'ProductTrashed',
      component: () => import('@/views/Product/ProductTrashedData.vue'),
      meta: {
        pageTitle: 'Product Trashed Data',
        breadcrumb: [
          {
            text: 'Product Trashed Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/ShowProduct/:id',
      name: 'ShowProduct',
      component: () => import('@/views/Product/ShowProduct.vue'),
      meta: {
        pageTitle: 'Show Product',
        breadcrumb: [
          {
            text: 'Show Product',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/ShowProduct/create/variant/:id',
      name: 'Create-Variant',
      component: () => import('@/views/Product/Variant/StoreVariant.vue'),
      meta: {
        pageTitle: 'Create Variant',
        breadcrumb: [
          {
            text: 'Create Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/Used/UpdateProductMainData/:id',
      name: 'UpdateProduct',
      component: () => import('@/views/Product/UpdateProductMainData.vue'),
      meta: {
        pageTitle: 'Update Product Main Data',
        breadcrumb: [
          {
            text: 'Update Product Main Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-product-status',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/UpdateTags/:id',
      name: 'UpdateProductTags',
      component: () => import('@/views/Product/UpdateTags.vue'),
      meta: {
        pageTitle: 'Update Tags',
        breadcrumb: [
          {
            text: 'Update Tags',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/UpdateAccessories/:id',
      name: 'UpdateProductAccessories',
      component: () => import('@/views/Product/UpdateAccessories.vue'),
      meta: {
        pageTitle: 'Update Tags',
        breadcrumb: [
          {
            text: 'Update Tags',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/:productID/UpdateTranslation/:id',
      name: 'UpdateProductTranslation',
      props: true,
      component: () => import('@/views/Product/UpdateItemTranslation'),
      meta: {
        pageTitle: 'Update Translation',
        breadcrumb: [
          {
            text: 'Update Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/addNewTranslation/:id',
      name: 'addNewProductTranslation',
      props: true,
      component: () => import('@/views/Product/addNewTranslation'),
      meta: {
        pageTitle: 'Add New Translation',
        breadcrumb: [
          {
            text: 'Add New Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/Used/Product/AddNewImage/:id',
      name: 'AddNewProductImage',
      props: true,
      component: () => import('@/views/Product/AddNewImage'),
      meta: {
        pageTitle: 'Add Image',
        breadcrumb: [
          {
            text: 'Add Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'update_product_media',
      },
    },
    {
      path: '/Used/ShowProduct/Variant/:id',
      name: 'ShowProductVariant',
      props: true,
      component: () => import('@/views/Product/ShowEAV'),
      meta: {
        pageTitle: 'Variant',
        breadcrumb: [
          {
            text: 'Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/ShowProduct/variant/show/:id',
      name: 'Show-Variant',
      props: true,
      component: () => import('@/views/Product/Variant/ShowVariant'),
      meta: {
        pageTitle: 'Variant',
        breadcrumb: [
          {
            text: 'Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/Used/ShowProduct/update/variant/:id',
      name: 'UpdateProductVariant',
      props: true,
      component: () => import('@/views/Product/Variant/UpdateVariant'),
      meta: {
        pageTitle: 'Update Variant',
        breadcrumb: [
          {
            text: 'Update Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-product-status',
        per2: 'variant',
      },
    },
    {
      path: '/Used/ShowProduct/variant/add/image/:id',
      name: 'AddUsedVariantImage',
      props: true,
      component: () => import('@/views/Product/Variant/AddNewImage'),
      meta: {
        pageTitle: 'Add Image',
        breadcrumb: [
          {
            text: 'Add Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'update_product_media',
      },
    },

    {
      path: '/categories/:id/New/Product/Add',
      name: 'AddNewProduct',
      component: () => import('@/views/NewProduct/AddProductWithOffer.vue'),
      meta: {
        pageTitle: 'Add New Product',
        breadcrumb: [
          {
            text: 'Add New Product',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/categories/:id/New/In-Store/Product/Add',
      name: 'AddNewProduct-InStore',
      component: () => import('@/views/NewProduct/AddProductInStore.vue'),
      meta: {
        pageTitle: 'Add Product',
        breadcrumb: [
          {
            text: 'Add Product',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/Index',
      name: 'NewProductIndex',
      component: () => import('@/views/NewProduct/ProductIndex.vue'),
      meta: {
        pageTitle: 'New Product Index',
        breadcrumb: [
          {
            text: 'New Product Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },

    {
      path: '/New/Product/Card/Index',
      name: 'NewProductCardIndex',
      component: () => import('@/views/NewProduct/ProductEcommIndex.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'New Products Card Index',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'New Products Card Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/Family/Index',
      name: 'NewProductFamilyIndex',
      component: () => import('@/views/NewProduct/FamilyProducts.vue'),
      meta: {
        pageTitle: 'New Products Family Index',
        breadcrumb: [
          {
            text: 'New Products Family Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/trashed/Index',
      name: 'NewProductTrashed',
      component: () => import('@/views/NewProduct/ProductTrashedData.vue'),
      meta: {
        pageTitle: 'New Product Trashed Data',
        breadcrumb: [
          {
            text: 'New Product Trashed Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/ShowProduct/:id',
      name: 'NewShowProduct',
      component: () => import('@/views/NewProduct/ShowProduct.vue'),
      meta: {
        pageTitle: 'Show New Product',
        breadcrumb: [
          {
            text: 'Show New Product',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/ShowProduct/create/variant/:id',
      name: 'Create-Variant-New',
      component: () => import('@/views/NewProduct/Variant/StoreVariant.vue'),
      meta: {
        pageTitle: 'Create New Variant',
        breadcrumb: [
          {
            text: 'Create New Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/New/UpdateProductMainData/:id',
      name: 'UpdateNewProduct',
      component: () => import('@/views/NewProduct/UpdateProductMainData.vue'),
      meta: {
        pageTitle: 'Update New Product Main Data',
        breadcrumb: [
          {
            text: 'Update New Product Main Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-product-status',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/UpdateTags/:id',
      name: 'UpdateNewProductTags',
      component: () => import('@/views/NewProduct/UpdateTags.vue'),
      meta: {
        pageTitle: 'Update Tags',
        breadcrumb: [
          {
            text: 'Update Tags',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/UpdateAccessories/:id',
      name: 'UpdateNewProductAccessories',
      component: () => import('@/views/NewProduct/UpdateAccessories.vue'),
      meta: {
        pageTitle: 'Update Accessories',
        breadcrumb: [
          {
            text: 'Update Accessories',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/:productID/UpdateTranslation/:id',
      name: 'UpdateNewProductTranslation',
      props: true,
      component: () => import('@/views/NewProduct/UpdateItemTranslation'),
      meta: {
        pageTitle: 'Update Translation',
        breadcrumb: [
          {
            text: 'Update Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/addNewTranslation/:id',
      name: 'addProductTranslation',
      props: true,
      component: () => import('@/views/NewProduct/addNewTranslation'),
      meta: {
        pageTitle: 'Add New Translation',
        breadcrumb: [
          {
            text: 'Add New Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'product',
      },
    },
    {
      path: '/New/Product/AddNewImage/:id',
      name: 'AddNewNewProductImage',
      props: true,
      component: () => import('@/views/NewProduct/AddNewImage'),
      meta: {
        pageTitle: 'Add Image',
        breadcrumb: [
          {
            text: 'Add Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'update_product_media',
      },
    },
    {
      path: '/New/ShowProduct/Variant/:id',
      name: 'ShowNewProductVariant',
      props: true,
      component: () => import('@/views/NewProduct/ShowEAV'),
      meta: {
        pageTitle: 'Variant',
        breadcrumb: [
          {
            text: 'Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/ShowProduct/Variant/value/:id',
      name: 'ShowNewProductVariantValue',
      props: true,
      component: () => import('@/views/NewProduct/ShowEAVvalue'),
      meta: {
        pageTitle: 'Variant',
        breadcrumb: [
          {
            text: 'Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/ShowProduct/variant/show/:id',
      name: 'Show-Variant-NewProduct',
      props: true,
      component: () => import('@/views/NewProduct/Variant/ShowVariant'),
      meta: {
        pageTitle: 'Variant',
        breadcrumb: [
          {
            text: 'Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'product',
      },
    },
    {
      path: '/New/ShowProduct/update/variant/:id',
      name: 'UpdateNewProductVariant',
      props: true,
      component: () => import('@/views/NewProduct/Variant/UpdateVariant'),
      meta: {
        pageTitle: 'Update Variant',
        breadcrumb: [
          {
            text: 'Update Variant',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update-product-status',
        per2: 'variant',
      },
    },
    {
      path: '/New/ShowProduct/variant/add/image/:id',
      name: 'AddNewVariantImage',
      props: true,
      component: () => import('@/views/NewProduct/Variant/AddNewImage'),
      meta: {
        pageTitle: 'Add Image',
        breadcrumb: [
          {
            text: 'Add Image',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'update_product_media',
      },
    },
    {
      path: '/Vendor/AddCondition',
      name: 'AddCondition',
      component: () => import('@/views/Vendor/AddCondition.vue'),
      meta: {
        pageTitle: 'Add Condition',
        breadcrumb: [
          {
            text: 'Add Condition',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'condition',
      },
    },
    {
      path: '/Vendor/ConditionIndex',
      name: 'ConditionIndex',
      component: () => import('@/views/Vendor/ConditionIndex.vue'),
      meta: {
        pageTitle: 'Condition Index',
        breadcrumb: [
          {
            text: 'Condition Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'condition',
      },
    },
    {
      path: '/Vendor/UpdateCondition/:id',
      name: 'UpdateCondition',
      props: true,
      component: () => import('@/views/Vendor/UpdateCondition.vue'),
      meta: {
        pageTitle: 'UpdateCondition',
        breadcrumb: [
          {
            text: 'UpdateCondition',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'condition',
      },
    },

    // *===============================================---*
    // *--------- Attrubites --------------------------------------------*
    // *===============================================---*
    {
      path: '/Attributes/Add',
      name: 'Attributes-Add',
      component: () => import('@/views/Ecommerce/Attributes/AddAttributes.vue'),
      meta: {
        pageTitle: 'Add Attributes',
        breadcrumb: [
          {
            text: 'Add Attributes',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'attribute',
      },
    },
    {
      path: '/Attributes/Index',
      name: 'Attributes-Index',
      component: () =>
        import('@/views/Ecommerce/Attributes/AttributesIndex.vue'),
      meta: {
        pageTitle: 'Attributes Index',
        breadcrumb: [
          {
            text: 'Attributes Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'attribute',
      },
    },
    {
      path: '/Attributes/Show/:id',
      name: 'Attributes-Show',
      props: true,
      component: () => import('@/views/Ecommerce/Attributes/ShowAttribute.vue'),
      meta: {
        pageTitle: 'Show Attribute',
        breadcrumb: [
          {
            text: 'Show Attribute',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'attribute',
      },
    },
    {
      path: '/Attributes/Translation/Add/:id',
      name: 'Attributes-AddTranslation',
      props: true,
      component: () =>
        import('@/views/Ecommerce/Attributes/AddTranslation.vue'),
      meta: {
        pageTitle: 'Attribute Add Translation',
        breadcrumb: [
          {
            text: 'Attribute Add Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'attribute',
      },
    },
    {
      path: '/Attributes/Update/:id',
      name: 'Attributes-Update',
      props: true,
      component: () =>
        import('@/views/Ecommerce/Attributes/UpdateAttribute.vue'),
      meta: {
        pageTitle: 'Update Attribute Translation',
        breadcrumb: [
          {
            text: 'Update Attribute',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'attribute',
      },
    },

    // *===============================================---*
    // *--------- values --------------------------------------------*
    // *===============================================---*
    {
      path: '/Values/Add',
      name: 'Values-Add',
      component: () => import('@/views/Ecommerce/Value/AddValue.vue'),
      meta: {
        pageTitle: 'Add Values',
        breadcrumb: [
          {
            text: 'Add Values',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'value',
      },
    },
    {
      path: '/Values/Index',
      name: 'Values-Index',
      component: () => import('@/views/Ecommerce/Value/ValuesIndex.vue'),
      meta: {
        pageTitle: 'Values Index',
        breadcrumb: [
          {
            text: 'Values Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'value',
      },
    },
    {
      path: '/Values/Show/:id',
      name: 'Values-Show',
      props: true,
      component: () => import('@/views/Ecommerce/Value/ShowValue.vue'),
      meta: {
        pageTitle: 'Show Value',
        breadcrumb: [
          {
            text: 'Show Value',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'value',
      },
    },
    {
      path: '/Values/Translation/Add/:id',
      name: 'Values-AddTranslation',
      props: true,
      component: () => import('@/views/Ecommerce/Value/AddTranslation.vue'),
      meta: {
        pageTitle: 'Value Add Translation',
        breadcrumb: [
          {
            text: 'Value Add Translation',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'value',
      },
    },
    {
      path: '/Values/Update/:id',
      name: 'Values-Update',
      props: true,
      component: () => import('@/views/Ecommerce/Value/UpdateValue.vue'),
      meta: {
        pageTitle: 'Update Values Translation',
        breadcrumb: [
          {
            text: 'Update Value',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'value',
      },
    },

    // *===============================================---*
    // *--------- Country --------------------------------------------*
    // *===============================================---*
    {
      path: '/Countries/Index',
      name: 'Countries-Index',
      component: () => import('@/views/Locations/Country/CountryIndex.vue'),
      meta: {
        pageTitle: 'Countries Index',
        breadcrumb: [
          {
            text: 'Countries Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'country',
      },
    },
    {
      path: '/Add/Country',
      name: 'Countries-Add',
      component: () => import('@/views/Locations/Country/AddCountry.vue'),
      meta: {
        pageTitle: 'Add Countries',
        breadcrumb: [
          {
            text: 'Add Countries',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'country',
      },
    },
    {
      path: '/Countries/Update/:id',
      name: 'Countries-Update',
      props: true,
      component: () => import('@/views/Locations/Country/UpdateCountry.vue'),
      meta: {
        pageTitle: 'Update Country',
        breadcrumb: [
          {
            text: 'Update Country',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'country',
      },
    },

    // *===============================================---*
    // *--------- City --------------------------------------------*
    // *===============================================---*
    {
      path: '/Cities/Index',
      name: 'Cities-Index',
      component: () => import('@/views/Locations/City/CityIndex.vue'),
      meta: {
        pageTitle: 'Cities Index',
        breadcrumb: [
          {
            text: 'Cities Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'city',
      },
    },
    {
      path: '/Add/City',
      name: 'Cities-Add',
      component: () => import('@/views/Locations/City/AddCity.vue'),
      meta: {
        pageTitle: 'Add Cities',
        breadcrumb: [
          {
            text: 'Add Cities',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'city',
      },
    },
    {
      path: '/Cities/Update/:id',
      name: 'Cities-Update',
      props: true,
      component: () => import('@/views/Locations/City/UpdateCity.vue'),
      meta: {
        pageTitle: 'Update City',
        breadcrumb: [
          {
            text: 'Update City',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'city',
      },
    },
    {
      path: '/Cities/trashed/index',
      name: 'Cities-Archived-Data',
      component: () => import('@/views/Locations/City/trashedCity.vue'),
      meta: {
        pageTitle: 'Cities Archived Data',
        breadcrumb: [
          {
            text: 'Cities Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'city',
      },
    },

    // *===============================================---*
    // *--------- Area --------------------------------------------*
    // *===============================================---*
    {
      path: '/Areas/Index',
      name: 'Areas-Index',
      component: () => import('@/views/Locations/Area/AreasIndex.vue'),
      meta: {
        pageTitle: 'Areas Index',
        breadcrumb: [
          {
            text: 'Areas Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'area',
      },
    },
    {
      path: '/Areas/Add',
      name: 'Areas-Add',
      component: () => import('@/views/Locations/Area/AddArea.vue'),
      meta: {
        pageTitle: 'Add Areas',
        breadcrumb: [
          {
            text: 'Add Areas',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'area',
      },
    },
    {
      path: '/Areas/trashed/index',
      name: 'Areas-Archived-Data',
      component: () => import('@/views/Locations/Area/TrashedArea.vue'),
      meta: {
        pageTitle: 'Areas Archived Data',
        breadcrumb: [
          {
            text: 'Areas Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'area',
      },
    },
    {
      path: '/Areas/Update/:id',
      name: 'Areas-Update',
      props: true,
      component: () => import('@/views/Locations/Area/UpdateArea.vue'),
      meta: {
        pageTitle: 'Update Area',
        breadcrumb: [
          {
            text: 'Update Area',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'area',
      },
    },

    // *===============================================---*
    // *--------- Couriers --------------------------------------------*
    // *===============================================---*
    {
      path: '/Courier/Add',
      name: 'Courier-Add',
      component: () => import('@/views/Couriers/AddCourier.vue'),
      meta: {
        pageTitle: 'Add Courier',
        breadcrumb: [
          {
            text: 'Add Courier',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Index',
      name: 'Courier-Index',
      component: () => import('@/views/Couriers/CouriersIndex.vue'),
      meta: {
        pageTitle: 'Couriers Index',
        breadcrumb: [
          {
            text: 'Couriers Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Trashed',
      name: 'Courier-Trashed',
      component: () => import('@/views/Couriers/CouriersTrashedData.vue'),
      meta: {
        pageTitle: 'Couriers Trashed',
        breadcrumb: [
          {
            text: 'Couriers Trashed',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Add/Area/:id',
      name: 'Courier-Add-Area',
      props: true,
      component: () => import('@/views/Couriers/AddAreaToCourier.vue'),
      meta: {
        pageTitle: 'Courier Add Area',
        breadcrumb: [
          {
            text: 'Courier Add Area',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Show/:id',
      name: 'Courier-Show',
      props: true,
      component: () => import('@/views/Couriers/ShowCourier.vue'),
      meta: {
        pageTitle: 'Show Courier',
        breadcrumb: [
          {
            text: 'Show Courier',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Update/:id',
      name: 'Courier-Update',
      props: true,
      component: () => import('@/views/Couriers/UpdateCourier.vue'),
      meta: {
        pageTitle: 'Update Courier',
        breadcrumb: [
          {
            text: 'Update Courier',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Update/Description',
      name: 'Courier-Update-Description',
      props: true,
      component: () => import('@/views/Couriers/UpdateTrans.vue'),
      meta: {
        pageTitle: 'Update Description',
        breadcrumb: [
          {
            text: 'Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'courier',
      },
    },
    {
      path: '/Courier/Add/Description',
      name: 'Courier-Add-Description',
      props: true,
      component: () => import('@/views/Couriers/AddTrans.vue'),
      meta: {
        pageTitle: 'Add Description',
        breadcrumb: [
          {
            text: 'Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'courier',
      },
    },

    // *===============================================---*
    // *--------- Coupons --------------------------------------------*
    // *===============================================---*
    {
      path: '/Coupon/Add',
      name: 'Coupon-Add',
      component: () => import('@/views/Coupons/AddCoupon.vue'),
      meta: {
        pageTitle: 'Add Coupon',
        breadcrumb: [
          {
            text: 'Add Coupon',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Index',
      name: 'Coupon-Index',
      component: () => import('@/views/Coupons/CouponsIndex.vue'),
      meta: {
        pageTitle: 'Coupons Index',
        breadcrumb: [
          {
            text: 'Coupons Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Archived',
      name: 'Coupon-Archived',
      component: () => import('@/views/Coupons/CouponArchived.vue'),
      meta: {
        pageTitle: 'Coupons Archived Data',
        breadcrumb: [
          {
            text: 'Coupons Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Trashed',
      name: 'Coupon-Trashed',
      component: () => import('@/views/Coupons/CouponsTrashedData.vue'),
      meta: {
        pageTitle: 'Coupons Trashed',
        breadcrumb: [
          {
            text: 'Coupons Trashed',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Update/:id',
      name: 'Coupon-Update',
      props: true,
      component: () => import('@/views/Coupons/UpdateCoupon.vue'),
      meta: {
        pageTitle: 'Update Coupon',
        breadcrumb: [
          {
            text: 'Update Coupon',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Show/:id',
      name: 'Coupon-Show',
      props: true,
      component: () => import('@/views/Coupons/ShowCoupon.vue'),
      meta: {
        pageTitle: 'Show Coupon',
        breadcrumb: [
          {
            text: 'Show Coupon',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Update/Description',
      name: 'Coupon-Update-Description',
      props: true,
      component: () => import('@/views/Coupons/UpdateTrans.vue'),
      meta: {
        pageTitle: 'Update Description',
        breadcrumb: [
          {
            text: 'Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'coupon',
      },
    },
    {
      path: '/Coupon/Add/Description',
      name: 'Coupon-Add-Description',
      props: true,
      component: () => import('@/views/Coupons/AddTrans.vue'),
      meta: {
        pageTitle: 'Add Description',
        breadcrumb: [
          {
            text: 'Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'coupon',
      },
    },

    // *===============================================---*
    // *--------- Offers --------------------------------------------*
    // *===============================================---*
    {
      path: '/Offer/Add',
      name: 'Offer-Add',
      component: () => import('@/views/Offers/AddOffer.vue'),
      meta: {
        pageTitle: 'Add Offer',
        breadcrumb: [
          {
            text: 'Add Offer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Index',
      name: 'Offers-Index',
      component: () => import('@/views/Offers/OffersIndex.vue'),
      meta: {
        pageTitle: 'Offers Index',
        breadcrumb: [
          {
            text: 'Offers Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Trashed',
      name: 'Offers-Trashed',
      component: () => import('@/views/Offers/OfferTrashedData.vue'),
      meta: {
        pageTitle: 'Trashed Offers',
        breadcrumb: [
          {
            text: 'Trashed Offers',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Update/:id',
      name: 'Offer-Update',
      props: true,
      component: () => import('@/views/Offers/UpdateOffer.vue'),
      meta: {
        pageTitle: 'Update Offer',
        breadcrumb: [
          {
            text: 'Update Offer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Show/:id',
      name: 'Offer-Show',
      props: true,
      component: () => import('@/views/Offers/ShowOffer.vue'),
      meta: {
        pageTitle: 'Show Offer',
        breadcrumb: [
          {
            text: 'Show Offer',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Update/Description',
      name: 'Offer-Update-Description',
      props: true,
      component: () => import('@/views/Offers/UpdateTrans.vue'),
      meta: {
        pageTitle: 'Update Description',
        breadcrumb: [
          {
            text: 'Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'offer',
      },
    },
    {
      path: '/Offer/Add/Description',
      name: 'Offer-Add-Description',
      props: true,
      component: () => import('@/views/Offers/AddTrans.vue'),
      meta: {
        pageTitle: 'Add Description',
        breadcrumb: [
          {
            text: 'Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'offer',
      },
    },

    // *===============================================---*
    // *--------- Bundles  --------------------------------------------*
    // *===============================================---*
    {
      path: '/bundle/add',
      name: 'Bundle-Add',
      component: () => import('@/views/Bundles/AddBundle.vue'),
      meta: {
        pageTitle: 'Add Bundle',
        breadcrumb: [
          {
            text: 'Add Bundle',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'bundle',
      },
    },
    {
      path: '/bundle/index',
      name: 'Bundles-Index',
      component: () => import('@/views/Bundles/index.vue'),
      meta: {
        pageTitle: 'Bundles Index',
        breadcrumb: [
          {
            text: 'Bundles Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'bundle',
      },
    },
    {
      path: '/bundle/archived',
      name: 'Bundles-Trashed',
      component: () => import('@/views/Bundles/ArchivedData.vue'),
      meta: {
        pageTitle: 'Bundles Archived Data',
        breadcrumb: [
          {
            text: 'Bundles Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'bundle',
      },
    },
    {
      path: '/bundle/update/:id',
      name: 'Bundles-Update',
      props: true,
      component: () => import('@/views/Bundles/UpdateBundle.vue'),
      meta: {
        pageTitle: 'Update Bundle',
        breadcrumb: [
          {
            text: 'Update Bundle',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'bundle',
      },
    },
    {
      path: '/bundle/show/:id',
      name: 'Bundles-Show',
      props: true,
      component: () => import('@/views/Bundles/ShowBundle.vue'),
      meta: {
        pageTitle: 'Show Bundle',
        breadcrumb: [
          {
            text: 'Show Bundle',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'bundle',
      },
    },
    {
      path: '/bundle/products/update/:id',
      name: 'Bundles-Products-Update',
      props: true,
      component: () => import('@/views/Bundles/SyncVariants.vue'),
      meta: {
        pageTitle: 'Bundle Products Update',
        breadcrumb: [
          {
            text: 'Bundle Products Update',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'bundle',
      },
    },

    // *===============================================---*
    // *--------- Carts ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/carts/Active',
      name: 'Cart-Active',
      component: () => import('@/views/Cart/ActiveCarts.vue'),
      meta: {
        pageTitle: 'Active Carts',
        breadcrumb: [
          {
            text: 'Active Carts',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'order',
        per2: 'show',
      },
    },

    // *===============================================---*
    // *--------- Users ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/User/Register',
      name: 'User-Add',
      component: () => import('@/views/Users/AddUser.vue'),
      meta: {
        pageTitle: 'Add User',
        breadcrumb: [
          {
            text: 'Add User',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'user',
      },
    },
    {
      path: '/User/Index',
      name: 'Users-Index',
      component: () => import('@/views/Users/UsersIndex.vue'),
      meta: {
        pageTitle: 'Users Index',
        breadcrumb: [
          {
            text: 'Users Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'user',
      },
    },
    {
      path: '/User/Trashed',
      name: 'Users-Trashed',
      component: () => import('@/views/Users/UsersTrashedData.vue'),
      meta: {
        pageTitle: 'Trashed Users',
        breadcrumb: [
          {
            text: 'Trashed Users',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'user',
      },
    },
    {
      path: '/User/Update/:id',
      name: 'User-Update',
      props: true,
      component: () => import('@/views/Users/UpdateUser.vue'),
      meta: {
        pageTitle: 'Update User',
        breadcrumb: [
          {
            text: 'Update User',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'user',
      },
    },
    {
      path: '/User/withdrawal/:id',
      name: 'User-withdrawal',
      props: true,
      component: () => import('@/views/Users/withdrawal.vue'),
      meta: {
        pageTitle: 'User withdrawal',
        breadcrumb: [
          {
            text: 'User withdrawal',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'user',
      },
    },
    {
      path: '/User/Show/:id',
      name: 'User-Show',
      props: true,
      component: () => import('@/views/Users/ShowUser.vue'),
      meta: {
        pageTitle: 'Show User',
        breadcrumb: [
          {
            text: 'Show User',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'user',
      },
    },

    // *===============================================---*
    // *--------- PriceRate --------------------------------------------*
    // *===============================================---*
    {
      path: '/used/product/pricing',
      name: 'Price-Rate',
      component: () => import('@/views/UsedPriceRate/index.vue'),
      meta: {
        pageTitle: 'Margin Fees',
        breadcrumb: [
          {
            text: 'Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'price_rate',
      },
    },
    {
      path: '/used/product/pricing/trahsed',
      name: 'Price-Rate-Trashed',
      component: () => import('@/views/UsedPriceRate/trashed.vue'),
      meta: {
        pageTitle: 'Margin Fees Archived Data',
        breadcrumb: [
          {
            text: 'Margin Fees Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'price_rate',
      },
    },
    {
      path: '/used/product/pricing/add',
      name: 'Price-Rate-Add',
      component: () => import('@/views/UsedPriceRate/add.vue'),
      meta: {
        pageTitle: 'Add Margin Fees',
        breadcrumb: [
          {
            text: 'Add Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'price_rate',
      },
    },
    {
      path: '/used/product/pricing/update/:id',
      props: true,
      name: 'Price-Rate-Update',
      component: () => import('@/views/UsedPriceRate/update.vue'),
      meta: {
        pageTitle: 'Update Margin Fees',
        breadcrumb: [
          {
            text: 'Update Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'price_rate',
      },
    },

    {
      path: '/new/product/pricing',
      name: 'New-Price-Rate',
      component: () => import('@/views/NewPriceRate/index.vue'),
      meta: {
        pageTitle: 'Margin Fees',
        breadcrumb: [
          {
            text: 'Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'item_rate_price',
      },
    },
    {
      path: '/new/product/pricing/trahsed',
      name: 'New-Price-Rate-Trashed',
      component: () => import('@/views/NewPriceRate/trashed.vue'),
      meta: {
        pageTitle: 'Margin Fees Archived Data',
        breadcrumb: [
          {
            text: 'Margin Fees Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'item_rate_price',
      },
    },
    {
      path: '/new/product/pricing/add',
      name: 'New-Price-Rate-Add',
      component: () => import('@/views/NewPriceRate/add.vue'),
      meta: {
        pageTitle: 'Add Margin Fees',
        breadcrumb: [
          {
            text: 'Add Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'item_rate_price',
      },
    },
    {
      path: '/new/product/pricing/update/:id',
      props: true,
      name: 'New-Price-Rate-Update',
      component: () => import('@/views/NewPriceRate/update.vue'),
      meta: {
        pageTitle: 'Update Margin Fees',
        breadcrumb: [
          {
            text: 'Update Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'item_rate_price',
      },
    },




    // *===============================================---*
    // *--------- InsurancePriceRate --------------------------------------------*
    // *===============================================---*
    {
      path: '/insurance/pricing',
      name: 'insurance-Rate',
      component: () => import('@/views/insurancePriceFees/index.vue'),
      meta: {
        pageTitle: 'Insurance Fees',
        breadcrumb: [
          {
            text: 'Insurance Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'insurance_fees',
      },
    },
    {
      path: '/insurance/pricing/trahsed',
      name: 'insurance-Rate-Trashed',
      component: () => import('@/views/insurancePriceFees/trashed.vue'),
      meta: {
        pageTitle: 'Insurance Fees Archived Data',
        breadcrumb: [
          {
            text: 'Insurance Fees Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'insurance_fees',
      },
    },
    {
      path: '/insurance/pricing/add',
      name: 'insurance-Rate-Add',
      component: () => import('@/views/insurancePriceFees/add.vue'),
      meta: {
        pageTitle: 'Add Insurance Margin Fees',
        breadcrumb: [
          {
            text: 'Add  Insurance Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'insurance_fees',
      },
    },
    {
      path: '/insurance/pricing/update/:id',
      props: true,
      name: 'insurance-Rate-Update',
      component: () => import('@/views/insurancePriceFees/update.vue'),
      meta: {
        pageTitle: 'Update Insurance Margin Fees',
        breadcrumb: [
          {
            text: 'Update Insurance Margin Fees',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'insurance_fees',
      },
    },

    // *===============================================---*
    // *--------- Diagnsotic Model ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/sensors',
      name: 'Sensors',
      component: () => import('@/views/SensorModel/index.vue'),
      meta: {
        pageTitle: 'Sensors',
        breadcrumb: [
          {
            text: 'Sensors',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/add',
      name: 'Sensor-Add',
      component: () => import('@/views/SensorModel/Add.vue'),
      meta: {
        pageTitle: 'Add Sensor',
        breadcrumb: [
          {
            text: 'Add Sensor',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/trashed',
      name: 'Sensors-Trashed',
      component: () => import('@/views/SensorModel/Trashed.vue'),
      meta: {
        pageTitle: 'Sensors Archived Data',
        breadcrumb: [
          {
            text: 'Sensors Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/show/:id',
      name: 'Sensors-Show',
      props: true,
      component: () => import('@/views/SensorModel/Show.vue'),
      meta: {
        pageTitle: 'Sensor',
        breadcrumb: [
          {
            text: 'Sensor',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/translation/update/:id',
      name: 'Sensor-Update-Trans',
      props: true,
      component: () => import('@/views/SensorModel/UpdateTranslation.vue'),
      meta: {
        pageTitle: 'Sensor Update Description',
        breadcrumb: [
          {
            text: 'Sensor Update Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/translation/add/:id',
      name: 'Sensor-Add-Trans',
      props: true,
      component: () => import('@/views/SensorModel/AddTrans.vue'),
      meta: {
        pageTitle: 'Sensor Add Description',
        breadcrumb: [
          {
            text: 'Sensor Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'sensor',
      },
    },
    {
      path: '/sensors/update/:id',
      name: 'Sensor-Update',
      props: true,
      component: () => import('@/views/SensorModel/UpdateSensor.vue'),
      meta: {
        pageTitle: 'Update Sensor',
        breadcrumb: [
          {
            text: 'Update Sensor',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'sensor',
      },
    },
    {
      path: '/diagnostic/reports',
      name: 'Diagnostic-Reports',
      component: () => import('@/views/DTReport/index.vue'),
      meta: {
        pageTitle: 'Diagnostic Reports',
        breadcrumb: [
          {
            text: 'Diagnostic Reports',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'diagnostic',
      },
    },
    {
      path: '/diagnostic/reports/show/:id',
      props: true,
      name: 'Diagnostic-Reports-Show',
      component: () => import('@/views/DTReport/Show.vue'),
      meta: {
        pageTitle: 'Show Diagnostic Reports',
        breadcrumb: [
          {
            text: 'Show Diagnostic Reports',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'diagnostic',
      },
    },

    {
      path: '/diagnostic/reports/trashed',
      name: 'Diagnostic-Reports-Trashed',
      component: () => import('@/views/DTReport/Trashed.vue'),
      meta: {
        pageTitle: 'Diagnostic Reports Archived Data',
        breadcrumb: [
          {
            text: 'Diagnostic Reports Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'diagnostic',
      },
    },

    // *===============================================---*
    // *--------- Payouts ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/payout/index',
      name: 'Payouts-Index',
      component: () => import('@/views/Payouts/index.vue'),
      meta: {
        pageTitle: 'Payouts Index',
        breadcrumb: [
          {
            text: 'Payouts Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payouts',
      },
    },
    {
      path: '/payout/change/status/:id',
      name: 'Payouts-Change-Status',
      props: true,
      component: () => import('@/views/Payouts/ChangeStatus.vue'),
      meta: {
        pageTitle: 'Change Status',
        breadcrumb: [
          {
            text: 'change Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'payouts',
      },
    },
    {
      path: '/payout/show/:id',
      name: 'Payouts-Show',
      props: true,
      component: () => import('@/views/Payouts/ShowPayout.vue'),
      meta: {
        pageTitle: 'Show Payout',
        breadcrumb: [
          {
            text: 'Show Payout',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payouts',
      },
    },
    {
      path: '/payout/update/:id',
      name: 'Payouts-Update',
      props: true,
      component: () => import('@/views/Payouts/update.vue'),
      meta: {
        pageTitle: 'Update Payout',
        breadcrumb: [
          {
            text: 'Update Payout',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'payouts',
      },
    },
    {
      path: '/payout/add',
      name: 'Payouts-Add',
      props: true,
      component: () => import('@/views/Payouts/TypeIndex.vue'),
      meta: {
        pageTitle: 'Add Payout',
        breadcrumb: [
          {
            text: 'Add Payout',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'payouts',
      },
    },


    {
      path: '/payout/add/vendors',
      name: 'Payouts-Add-vendors',
      props: true,
      component: () => import('@/views/Payouts/storePayouts.vue'),
      meta: {
        pageTitle: 'Add Payout Vendor',
        breadcrumb: [
          {
            text: 'Add Payout Vendor',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'payouts',
      },
    },


    {
      path: '/payout/add/users',
      name: 'Payouts-Add-user',
      props: true,
      component: () => import('@/views/Payouts/storeUserPayout.vue'),
      meta: {
        pageTitle: 'Add Payout User',
        breadcrumb: [
          {
            text: 'Add Payout User',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'payouts',
      },
    },


    {
      path: '/company/financial/account',
      name: 'Company-Financial-Account',
      component: () => import('@/views/CompanyFinancialAccount.vue'),
      meta: {
        pageTitle: 'Company Financial Account',
        breadcrumb: [
          {
            text: 'Company Financial Account',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'financial',
      },
    },

    {
      path: '/charts',
      name: 'charts',
      component: () => import('@/views/charts.vue'),
      meta: {
        pageTitle: 'charts',
        breadcrumb: [
          {
            text: 'charts',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/sales-reports',
      name: 'Sales Report',
      component: () => import('@/views/AccountingReports/SalesReport.vue'),
      meta: {
        pageTitle: 'Sales Report',
        breadcrumb: [
          {
            text: 'Sales Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'sales_report',
      },
    },
    {
      path: '/report/transaction/sales-reports',
      name: 'Sales Report Transaction Level',
      component: () => import('@/views/AccountingReports/SalesReportTransactionLevel.vue'),
      meta: {
        pageTitle: 'Sales Report Transaction Level',
        breadcrumb: [
          {
            text: 'Sales Report Transaction Level',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'sales_report',
      },
    },
    {
      path: '/report/cash-collection',
      name: 'Cash-Collection',
      component: () => import('@/views/AccountingReports/CashCollection.vue'),
      meta: {
        pageTitle: 'Cash Collection',
        breadcrumb: [
          {
            text: 'Cash Collection',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/out-for-delivery',
      name: 'Out-For-Delivery',
      component: () => import('@/views/AccountingReports/OutForDelivery.vue'),
      meta: {
        pageTitle: 'Out For Delivery',
        breadcrumb: [
          {
            text: 'Out For Delivery',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/settled-sales',
      name: 'Settled-Sales',
      component: () => import('@/views/AccountingReports/SettledSales.vue'),
      meta: {
        pageTitle: 'Settled Sales',
        breadcrumb: [
          {
            text: 'Settled Sales',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/vendor-payout',
      name: 'Vendor-Payout',
      component: () => import('@/views/AccountingReports/VendorPayout.vue'),
      meta: {
        pageTitle: 'Vendor Payout',
        breadcrumb: [
          {
            text: 'Vendor Payout',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/sales',
      name: 'Total-Sale',
      component: () => import('@/views/AccountingReports/totalSale.vue'),
      meta: {
        pageTitle: 'Sales Report',
        breadcrumb: [
          {
            text: 'Sales Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/billing',
      name: 'Billing-Report',
      component: () => import('@/views/AccountingReports/billing.vue'),
      meta: {
        pageTitle: 'Billing Report',
        breadcrumb: [
          {
            text: 'Billing Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/revenu',
      name: 'Revenu-Report',
      component: () => import('@/views/AccountingReports/Revenu.vue'),
      meta: {
        pageTitle: 'Revenu Report',
        breadcrumb: [
          {
            text: 'Revenu Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },

    {
      path: '/report/stock',
      name: 'Stock-Report',
      component: () => import('@/views/AccountingReports/Stock.vue'),
      meta: {
        pageTitle: 'Stock Report',
        breadcrumb: [
          {
            text: 'Stock Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/user/orders',
      name: 'User-Orders-Report',
      component: () => import('@/views/AccountingReports/UserOrders.vue'),
      meta: {
        pageTitle: 'User Orders Report',
        breadcrumb: [
          {
            text: 'User Orders Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },
    {
      path: '/report/vendor/orders',
      name: 'Vendor-Orders-Report',
      component: () => import('@/views/AccountingReports/VendorOrders.vue'),
      meta: {
        pageTitle: 'Vendor Orders Report',
        breadcrumb: [
          {
            text: 'Vendor Orders Report',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'report',
      },
    },

    // *===============================================---*
    // *--------- Payment Provider -------------------------------------------*
    // *===============================================---*
    {
      path: '/payment-providers',
      name: 'PaymentProviders-Index',
      component: () => import('@/views/Settings/PaymentProvider.vue'),
      meta: {
        pageTitle: 'Payment Providers',
        breadcrumb: [
          {
            text: 'Payment Providers',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment-providers',
      },
    },
    // *===============================================---*
    // *--------- Setting -------------------------------------------*
    // *===============================================---*
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'setting',
      },
    },
    {
      path: '/settings/add',
      name: 'Settings-Add',
      component: () => import('@/views/Settings/Add.vue'),
      meta: {
        pageTitle: 'Add Settings',
        breadcrumb: [
          {
            text: 'Add Settings',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create_setting',
        per2: 'setting',
      },
    },
    {
      path: '/settings/update/:id',
      name: 'Settings-Update',
      component: () => import('@/views/Settings/Update.vue'),
      meta: {
        pageTitle: 'Add Setting',
        breadcrumb: [
          {
            text: 'Add Setting',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update_setting',
        per2: 'setting',
      },
    },
    {
      path: '/settings/index',
      name: 'Settings-Index',
      component: () => import('@/views/Settings/TypeIndex.vue'),
      meta: {
        pageTitle: 'Index Settings',
        breadcrumb: [
          {
            text: 'Index Settings',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'setting',
      },
    },
    {
      path: '/settings/index/:type',
      name: 'Settings-Type-Index',
      component: () => import('@/views/Settings/IndexByType.vue'),
      meta: {
        pageTitle: 'Index Settings',
        breadcrumb: [
          {
            text: 'Index Settings',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'setting',
      },
    },
    {
      path: '/settings/:attribute/:action',
      name: 'Settings-Manage',
      component: () => import('@/views/Settings/ManageSetting.vue'),
      meta: {
        pageTitle: 'Show Settings',
        breadcrumb: [
          {
            text: 'Show Settings',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'setting',
      },
      beforeEnter: (to, from, next) => {
        if (to.params.action === 'update') {
          to.meta.pageTitle = 'Update Settings'
          to.meta.breadcrumb[0].text = 'Update Settings'
          to.meta.per = 'update_setting'
        }
        next()
      }
    },

    // *===============================================---*
    // *--------- Accessory ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/accessory/index',
      name: 'Accessory-Index',
      component: () => import('@/views/Accessory/index.vue'),
      meta: {
        pageTitle: 'Accessory Index',
        breadcrumb: [
          {
            text: 'Accessory Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'accessory',
      },
    },
    {
      path: '/accessory/add',
      name: 'Accessory-Add',
      component: () => import('@/views/Accessory/add.vue'),
      meta: {
        pageTitle: 'Add Accessory',
        breadcrumb: [
          {
            text: 'Add Accessory',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'accessory',
      },
    },
    {
      path: '/accessory/trashed',
      name: 'Accessory-Trashed',
      component: () => import('@/views/Accessory/trashed.vue'),
      meta: {
        pageTitle: 'Accessory trashed',
        breadcrumb: [
          {
            text: 'Accessory trashed',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'accessory',
      },
    },
    {
      path: '/accessory/show/:id',
      name: 'Accessory-Show',
      props: true,
      component: () => import('@/views/Accessory/show.vue'),
      meta: {
        pageTitle: 'Show Accessory',
        breadcrumb: [
          {
            text: 'Show Accessory',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'accessory',
      },
    },
    {
      path: '/accessory/update/name',
      name: 'Accessory-Update-Name',
      props: true,
      component: () => import('@/views/Accessory/UpdateTrasnaltion.vue'),
      meta: {
        pageTitle: 'Update Accessory Name',
        breadcrumb: [
          {
            text: 'Update Accessory Name',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'accessory',
      },
    },
    {
      path: '/accessory/add/description/:id',
      name: 'accessory-Add-Trans',
      props: true,
      component: () => import('@/views/Accessory/AddTrans.vue'),
      meta: {
        pageTitle: 'Accessory Add Description',
        breadcrumb: [
          {
            text: 'Accessory Add Description',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'accessory',
      },
    },

    {
      path: '/accessory/update/categories/:id',
      name: 'accessory-Update',
      props: true,
      component: () => import('@/views/Accessory/updatecategories.vue'),
      meta: {
        pageTitle: 'Accessory update Categories',
        breadcrumb: [
          {
            text: 'Accessory update Categories',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'accessory',
      },
    },

    // *===============================================---*
    // *--------- Blog ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/post-category/add',
      name: 'Blog-Post-Category-Add',
      component: () => import('@/views/Blog/Category/Add.vue'),
      meta: {
        pageTitle: 'Add Post Category',
        breadcrumb: [
          {
            text: 'Add Post Category',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'post_category',
      },
    },
    {
      path: '/post-category/index',
      name: 'Blog-Post-Category-Index',
      component: () => import('@/views/Blog/Category/Index.vue'),
      meta: {
        pageTitle: 'Post Category Index',
        breadcrumb: [
          {
            text: 'Post Category Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'post_category',
      },
    },
    {
      path: '/post-category/trashed',
      name: 'Blog-Post-Category-Trashed',
      component: () => import('@/views/Blog/Category/Trashed.vue'),
      meta: {
        pageTitle: 'Post Category Archived Data',
        breadcrumb: [
          {
            text: 'Post Category Archived Data',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'post_category',
      },
    },
    {
      path: '/post-category/update/:id',
      name: 'Blog-Post-Category-Update',
      component: () => import('@/views/Blog/Category/Update.vue'),
      meta: {
        pageTitle: 'Update Post Category',
        breadcrumb: [
          {
            text: 'Update Post Category',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'post_category',
      },
    },

    {
      path: '/posts/index',
      name: 'Blog-Posts-Index',
      component: () => import('@/views/Blog/Post/index.vue'),
      meta: {
        pageTitle: 'Posts Index',
        breadcrumb: [
          {
            text: 'Posts Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'post',
      },
    },
    {
      path: '/posts/add',
      name: 'Blog-Posts-Add',
      component: () => import('@/views/Blog/Post/Add.vue'),
      meta: {
        pageTitle: 'Add Post',
        breadcrumb: [
          {
            text: 'Add Post',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'post',
      },
    },
    {
      path: '/posts/show/:id',
      name: 'Blog-Posts-Show',
      props: true,
      component: () => import('@/views/Blog/Post/Show.vue'),
      meta: {
        pageTitle: 'Show Post',
        breadcrumb: [
          {
            text: 'Show Post',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'post',
      },
    },
    {
      path: '/posts/update/:id',
      name: 'Blog-Posts-Update',
      props: true,
      component: () => import('@/views/Blog/Post/Update.vue'),
      meta: {
        pageTitle: 'Update Post',
        breadcrumb: [
          {
            text: 'Update Post',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'post',
      },
    },

    // *===============================================---*
    // *--------- Refund ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/orders/refund/index',
      name: 'Refund-Index',
      component: () => import('@/views/Refund/Index.vue'),
      meta: {
        pageTitle: 'Refund Index',
        breadcrumb: [
          {
            text: 'Refund Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'refund',
      },
    },
    {
      path: '/orders/refund/:id',
      name: 'Refund-Create',
      props: true,
      component: () => import('@/views/Refund/Store.vue'),
      meta: {
        pageTitle: 'Store Refund',
        breadcrumb: [
          {
            text: 'Store Refund',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'create',
        per2: 'refund',
      },
    },
    {
      path: '/orders/refund/show/:id',
      name: 'Refund-Show',
      props: true,
      component: () => import('@/views/Refund/Show.vue'),
      meta: {
        pageTitle: 'Show Refund',
        breadcrumb: [
          {
            text: 'Show Refund',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'refund',
      },
    },

    // *===============================================---*
    // *--------- Problem ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/problems/index',
      name: 'Problems-Index',
      component: () => import('@/views/Problem/Index.vue'),
      meta: {
        pageTitle: 'Problem Index',
        breadcrumb: [
          {
            text: 'Problem Index',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'problem',
      },
    },
    {
      path: '/problems/change-status/:id',
      name: 'Problems-Change-Status',
      probs: true,
      component: () => import('@/views/Problem/ChangeStatus.vue'),
      meta: {
        pageTitle: 'Change Problem Status',
        breadcrumb: [
          {
            text: 'Change Problem Status',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'update',
        per2: 'problem',
      },
    },

    // *===============================================---*
    // *--------- Top Products ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/top-products',
      name: 'top-products',
      component: () => import('@/views/TopProducts.vue'),
      meta: {
        pageTitle: 'Top Products',
        breadcrumb: [
          {
            text: 'Top Products',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'variant',
      },
    },
    // *===============================================---*
    // *--------- Logger ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/products/main-table',
      name: 'products-main-table',
      component: () => import('@/views/MainTable/ProductsMainTable.vue'),
      meta: {
        pageTitle: 'Products Main Table',
        breadcrumb: [
          {
            text: 'Products Main Table',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'variant',
      },
    },
    {
      path: '/ads/main-table',
      name: 'ads-main-table',
      component: () => import('@/views/MainTable/AdsMainTable.vue'),
      meta: {
        pageTitle: 'Ads Main Table',
        breadcrumb: [
          {
            text: 'Ads Main Table',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'variant',
      },
    },
    {
      path: '/ads/active-main-table',
      name: 'ads-active-main-table',
      component: () => import('@/views/MainTable/ActiveAdsMainTable.vue'),
      meta: {
        pageTitle: 'Active Ads Main Table',
        breadcrumb: [
          {
            text: 'Active Ads Main Table',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'variant',
      },
    },
    {
      path: '/products/main-table/archived',
      name: 'products-table-archived',
      component: () => import('@/views/MainTable/ArchivedProductsMainTable.vue'),
      meta: {
        pageTitle: 'Archived Products Main Table',
        breadcrumb: [
          {
            text: 'Archived Products Main Table',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'variant',
      },
    },
    {
      path: '/ads/main-table/archived',
      name: 'ads-table-archived',
      component: () => import('@/views/MainTable/ArchivedAdsMainTable.vue'),
      meta: {
        pageTitle: 'Archived Ads Main Table',
        breadcrumb: [
          {
            text: 'Archived Ads Main Table',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-trashed',
        per2: 'variant',
      },
    },

   

    {
      path: '/newsletters/send',
      name: 'Send-newsletters',
      component: () => import('@/views/Newsletters/SendNewsletters.vue'),
      meta: {
        pageTitle: 'Send Newsletters',
        breadcrumb: [
          {
            text: 'Send Newsletters',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'send-newsletters',
        per2: 'send-newsletters',
      },
    },
    
    {
      path: '/newsletters/index',
      name: 'Show-newsletters',
      component: () => import('@/views/Newsletters/ShowNewsletters.vue'),
      meta: {
        pageTitle: 'show Newsletters',
        breadcrumb: [
          {
            text: 'Show Newsletters',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show-newsletters',
        per2: 'show-newsletters',
      },
    },


    


    {
      path: '/subscriptions',
      name: 'index-subscriptions',
      component: () => import('@/views/Subscriptions/IndexSubscriptions.vue'),
      meta: {
        pageTitle: 'Show Users Subscriptions',
        breadcrumb: [
          {
            text: 'Show Subscriptions',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'subscriptions',
        per2: 'show-subscriptions',
      },
    },

    
    {
      path: '/subscription/show/:id',
      name: 'view-subscriptions',
      component: () => import('@/views/Subscriptions/ViewSubscriptions.vue'),
      meta: {
        pageTitle: 'Show Users Subscriptions',
        breadcrumb: [
          {
            text: 'Show Subscriptions',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'subscriptions',
        per2: 'create',
      },
    },



    {
      path: '/subscriptions/trashed',
      name: 'trashed-subscriptions',
      component: () => import('@/views/Subscriptions/TrashedSubscriptions.vue'),
      meta: {
        pageTitle: 'Trashed Subscriptions',
        breadcrumb: [
          {
            text: 'Trashed Subscriptions',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'subscriptions',
        per2: 'show-trashed',
      },
    },

      {
        path: '/subscriptions/show/invoice/:id',
        name: 'invoice-subscriptions',
        props: true,
        component: () => import('@/views/Subscriptions/invoice.vue'),
        meta: {
          requiresAuth: true,
          per: 'subscriptions',
          per2: 'subscriptions',
        },
    },


    {
      path: '/packages/index',
      name: 'Show-packages',
      component: () => import('@/views/Subscriptions/ShowPackages.vue'),
      meta: {
        pageTitle: 'show Packages',
        breadcrumb: [
          {
            text: 'Show Packages',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'packages',
        per2: 'show-packages',
      },
    },
    {
      path: '/packages/create',
      name: 'Add-packages',
      component: () => import('@/views/Subscriptions/AddPackages.vue'),
      meta: {
        pageTitle: 'Add Packages',
        breadcrumb: [
          {
            text: 'Add Packages',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'packages',
        per2: 'create-package',
      },
    },

    {
      path: '/packages/trashed',
      name: 'trashed-packages',
      component: () => import('@/views/Subscriptions/TrashedPackages.vue'),
      meta: {
        pageTitle: 'Trashed Packages',
        breadcrumb: [
          {
            text: 'Trashed Packages',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'packages',
        per2: 'create-package',
      },
    },

    



    {
      path: '/activity-log',
      name: 'Activity-Log',
      component: () => import('@/views/logger.vue'),
      meta: {
        pageTitle: 'Activity Log',
        breadcrumb: [
          {
            text: 'Activity Log',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'activity_log',
      },
    },

    // *===============================================---*
    // *--------- transaction ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/transaction',
      name: 'Transaction',
      component: () => import('@/views/Transaction/index.vue'),
      meta: {
        pageTitle: 'Transaction',
        breadcrumb: [
          {
            text: 'Transaction',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment',
      },
    },
    {
      path: '/transaction/show/:id',
      name: 'Transaction-Show',
      props: true,
      component: () => import('@/views/Transaction/show.vue'),
      meta: {
        pageTitle: 'Transaction Show',
        breadcrumb: [
          {
            text: 'Transaction Show',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'show',
        per2: 'payment',
      },
    },


    // *===============================================---*
    // *--------- Ads -------------------------------------------*
    // *===============================================---*
    // {
    //   path: '/ads/index',
    //   name: 'AdsIndex',
    //   props: true,
    //   component: () => import('@/views/Ads/Index.vue'),
    //   meta: {
    //     pageTitle: 'Ads Index',
    //     breadcrumb: [
    //       {
    //         text: 'Ads Index',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show',
    //     per2: 'ads',
    //   },
    // },
    // {
    //   path: '/ads/show/:id',
    //   name: 'AdsShow',
    //   props: true,
    //   component: () => import('@/views/Ads/Show.vue'),
    //   meta: {
    //     pageTitle: 'Ads Show',
    //     breadcrumb: [
    //       {
    //         text: 'Ads Show',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'show',
    //     per2: 'ads',
    //   },
    // },
    // {
    //   path: '/ads/update/:id',
    //   name: 'AdsUpdate',
    //   props: true,
    //   component: () => import('@/views/Ads/Update.vue'),
    //   meta: {
    //     pageTitle: 'Ads Update',
    //     breadcrumb: [
    //       {
    //         text: 'Ads Update',
    //         active: true,
    //       },
    //     ],
    //     requiresAuth: true,
    //     per: 'update-ads-status',
    //     per2: 'ads',
    //   },
    // },

    // *===============================================---*
    // *--------- Operations Actions --------------------------------*
    // *===============================================---*
    {
      path: '/order/actions',
      name: 'Operation-Order-Action',
      props: true,
      component: () => import('@/views/OperationActions/orderActions.vue'),
      meta: {
        pageTitle: 'Order Action',
        breadcrumb: [
          {
            text: 'Order Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-order-actions',
        per2: 'operation-actions',
      },
    },
    {
      path: '/paymentSession/actions',
      name: 'Operation-PaymentSession-Action',
      props: true,
      component: () => import('@/views/OperationActions/PaymentSessionActions.vue'),
      meta: {
        pageTitle: 'PaymentSession Action',
        breadcrumb: [
          {
            text: 'PaymentSession Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-paymentSession-actions',
        per2: 'operation-actions',
      },
    },
    {
      path: '/bidding/actions',
      name: 'Operation-Bidding-Action',
      props: true,
      component: () => import('@/views/OperationActions/BiddingActions.vue'),
      meta: {
        pageTitle: 'Bidding Action',
        breadcrumb: [
          {
            text: 'Bidding Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-bidding-actions',
        per2: 'operation-actions',
      },
    },
    {
      path: '/variant/actions',
      name: 'Operation-Variant-Action',
      props: true,
      component: () => import('@/views/OperationActions/variantActions.vue'),
      meta: {
        pageTitle: 'Variant Action',
        breadcrumb: [
          {
            text: 'Variant Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-product-actions',
        per2: 'operation-actions',
      },
    },
    {
      path: '/refund/actions',
      name: 'Operation-Refund-Action',
      props: true,
      component: () => import('@/views/OperationActions/refundActions.vue'),
      meta: {
        pageTitle: 'Refund Action',
        breadcrumb: [
          {
            text: 'Refund Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-refund-actions',
        per2: 'operation-actions',
      },
    },
    {
      path: '/diagnostic/actions',
      name: 'Operation-Diagnostic-Action',
      props: true,
      component: () => import('@/views/OperationActions/DiagnosticActions.vue'),
      meta: {
        pageTitle: 'Diagnostic Report Action',
        breadcrumb: [
          {
            text: 'Diagnostic Report Action',
            active: true,
          },
        ],
        requiresAuth: true,
        per: 'operation-diagnostic-actions',
        per2: 'operation-actions',
      },
    },
  ],
})

const openRoutes = ['login']

// Main and final Routing
router.beforeEach((to, from, next) => {


  // check if isAuthenticated equal true
  // this mean that the token in the storage not null
  if (store.getters.isAuthenticated) {
    
    // get and validate the AuthenticatedUser state from getter state
    const checkAuthenticatedUser = store.getters['User/checkAuthenticatedUser']

    // if checkAuthenticatedUser equal to null
    // this mean that the request is the initial request 
    // if not equal null (this mean that the state have valid permissions and user)
    if (checkAuthenticatedUser == null) {

      // check if there is stored authPermissions in the locale storage
      if(localStorage.getItem('authPermissions') != null){
        
        // if authenticated user in the state equal to null 
        // then get the authenticated permission from the storage
        const localStorageAuthPermissions = Vue.CryptoJS.AES.decrypt(
          localStorage.getItem('authPermissions'),
          'EncryptDataMobileMasr'
        ).toString(Vue.CryptoJS.enc.Utf8)
  
        store.commit('setAuthPermission', JSON.parse(localStorageAuthPermissions))
        store.dispatch('User/getAuthenticatedUserWithPermissions')

      }else{

        store.dispatch('logout')
        return;
      }
    }

    const perm = store.state.authPermissions

    if (
      to.name != 'Home' &&
      to.name != 'login' &&
      to.name != '404' &&
      to.name != 'Admin-Profile' &&
      to.name != 'Admin-EditeProfile' &&
      to.name != 'Admin-Change-Password'
    ) {
      if (perm.includes(to.meta.per) && perm.includes(to.meta.per2)) {
        // //console.log('user hava permission')
        next()
      }
      // else {
      //   next('/')
      // }
    } else {
      if (openRoutes.includes(to.name)) {
        // //console.log('try to login with auth')
        next('/')
      } else {
        next()
        // //console.log('global pages')
      }
    }
  } else {
    if (openRoutes.includes(to.name)) {
      // //console.log('try to login from router')
      next()
    } else {
      // //console.log('not authorized')
      next('/login')
    }
  }
})

export default router
