// import axios from '@/libs/axios'

import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    users_id: null,
  },
  getters: {

  },
  mutations: {
    saveUsersId(state, payload){
        state.users_id = payload
    }
  },
  actions: {
  
  
  },
  
}


