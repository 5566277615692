import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/libs/axios'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import router from '../router/index'
import ecommerceStoreModule from '@/views/PhoneGuide/eCommerceStoreModule'
import producteCommerceStoreModule from '@/views/Product/producteCommerceStoreModule'
import NewproducteCommerceStoreModule from '@/views/NewProduct/producteCommerceStoreModule'

import Setting from './Modules/Setting'
import Ads from './Modules/Ads'
import User from './Modules/User'
import Errors from './Modules/Errors'
import Emails from './Modules/Emails'
import notifications from './Modules/notifications'
import CryptoJS from 'crypto-js';
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        'app-ecommerce': ecommerceStoreModule,
        'commerce-store': producteCommerceStoreModule,
        'New-commerce-store': NewproducteCommerceStoreModule,

        Setting,
        User,
        Ads,
        Errors,
        Emails,
        notifications
    },
    strict: process.env.DEV,

    state: {
        authPermissions: [],
        permissions: [],
        token: localStorage.getItem('token') ?  CryptoJS.AES.encrypt(localStorage.getItem('token'), "encryptionSecret").toString() : '',
        authType: localStorage.getItem('type') || '',
        status: '',

    },
    mutations: {
        setpermissions(state, permissions) {
            state.permissions = permissions;

        },
        authSuccess(state, token, authType) {
            state.token = token;
            state.status = 'success';
            state.authType = authType;
        },

        authError(state) {
            state.token = '';
            state.status = 'error';

            state.authType = '';
        },
        authLogout(state) {
            state.token = null;
            state.authType = null;
        },
        setAuthPermission(state , permissions) {
            state.authPermissions = permissions;
            // //console.log(state.authPermissions)
        },

    },

    actions: {
        login(context, payload) {

            return new Promise((resolve, reject) => {

                axios.post('login', payload)
                    .then((response) => {

                        if (response.status === 200) {
                            const userData = response.data.data

                            //token
                            // let accessToken =    
                            if(response.data.message != "OTP sent successfully")
                            {

                            
                            if(userData.access_token){

                            const accessToken = Vue.CryptoJS.AES.encrypt(userData.access_token, "encryptionSecret").toString()
                            localStorage.setItem('token', accessToken)
                            context.commit('authSuccess', accessToken)
                            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken
                            localStorage.setItem("token", accessToken);
                        }                   

                            //permissions
                            const permissions = []
                            const authPermisions = []
                            for (let rol in userData.role) {
                               
                                for (let per in userData.role[rol].permissions) {
                                    authPermisions.push(userData.role[rol].permissions[per].name)
                                    
                                    const encryptedPer = Vue.CryptoJS.AES.encrypt(userData.role[rol].permissions[per].name, "EncryptDataMobileMasr").toString()
                                    permissions.push(encryptedPer)
                                }
                            }

                            // get and set auth permissions in localStorage with incrypted array
                            //  and state
                            context.commit('setAuthPermission', authPermisions)
                            localStorage.setItem('authPermissions', Vue.CryptoJS.AES.encrypt(JSON.stringify(authPermisions), "EncryptDataMobileMasr").toString())
                            
                            context.commit('setpermissions', permissions)
                            localStorage.setItem('per', permissions)

                            // userData
                            const encryptedUserData = Vue.CryptoJS.AES.encrypt(JSON.stringify(userData), "EncryptDataMobileMasr").toString()
                            localStorage.setItem('userData', encryptedUserData)


                            // adminstration type
                            const encryptedAdminstration = Vue.CryptoJS.AES.encrypt(userData.adminstration, "EncryptDataMobileMasr").toString()
                            localStorage.setItem("adminstration", encryptedAdminstration);
                            this.state.authType = userData.adminstration


                            router.push({
                                name: 'Home'
                            })
                        }

                            resolve(response);
                    }

                    })
                    .catch((error) => {
                       
                        localStorage.removeItem('token');
                        context.commit('authError')

                        reject(error);
                    })

            })

        },

        logout(context) {
            //console.log("logout")
            return new Promise((resolve, reject) => {
                axios.post('logout').then((result) => {
                    context.commit('authLogout')
                    localStorage.clear();
                    delete axios.defaults.headers.common['Authorization'];
                    router.push({
                        path: '/login'
                    })
                    resolve(result);
                }).catch((err) => {
                    
                    reject(err)
                });



            })
        },

        axiosLogout(context) {
            //console.log("axiosLogout")
            return new Promise((resolve, reject) => {

                context.commit('authLogout')
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
                router.push({
                    path: '/login'
                }).catch(()=>{})




            })
        },

    },
    getters: {
        isAuthenticated: state => !!state.token,
        authStatus: state => state.status,
        /*    authType: state => state.authType,*/

        menus: (state, getters) => {
            
            if (getters.isAuthenticated) {
                return [{
                    name: "Logout",
                    route: "Logout"
                }]
            }
            return [
                { name: "login", route: "/Login" },
            ];
        }
    }
})
