// import axios from '@/libs/axios'

import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    user: null,
    isAuthenticatedUser: null,
  },
  getters: {
  
    checkAuthenticatedUser(context) {
      return context.isAuthenticatedUser
      
    },
  },
  mutations: {},
  actions: {
    // get permissions for authenticated user
     getAuthenticatedUserWithPermissions(context) {
         return new Promise((resolve, reject) => {

          
           axios
            .get('admins/auth/permissions/get')
            .then((response) => {
              // //console.log(response)
              // //console.log("get the initial user with permissions")
              if (response.status === 200) {
                
                context.state.isAuthenticatedUser = true
                context.rootState.authPermissions = response.data.data.permissions
                context.state.user = response.data.data.admin
                
                localStorage.setItem('authPermissions', Vue.CryptoJS.AES.encrypt(JSON.stringify(response.data.data.permissions), "EncryptDataMobileMasr").toString())
                
                resolve(response);
              }
              
            })
            .catch((error) => {
              context.state.isAuthenticatedUser = false
              reject(error)
            })

            })
    },
  },
  
}


