<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook

import eventBus from '@/libs/bus'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import Vue from 'vue'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
// import router from './router/index'
// import axios from '@/libs/axios'
// import { eventHub } from '@/libs/eventhub'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () =>
  import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // this.$store.dispatch('appConfig/setLanguage', this.languageSelect)
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }
    // const lngu = this.$session.get('lang');
    // if (lngu != null) {
    //   Vue.prototype.$hostlang = this.$session.get('lang');
    // } else {
    //   Vue.prototype.$hostlang = 'en'
    // }
    //set language
    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }
    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  async created() {
    if(localStorage.getItem('token')){

      const localStorageaccessToken = Vue.CryptoJS.AES.decrypt(localStorage.getItem('token'),'encryptionSecret' ).toString(Vue.CryptoJS.enc.Utf8);
      const access_token = localStorageaccessToken

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
    }

    // this.syncUserPermission()

    // window.onpopstate = (event) => {
    //   if (
    //     window.localStorage.getItem('token') !== null &&
    //     this.$route.path == '/login'
    //   ) {
    //     //console.log('try to login from app.vue')

    //     // this.$router.push('/') // redirect to home, for example
    //   }
    // }

    eventBus.$on('before-request', this.showSpinner)
    eventBus.$on('request-error', this.hideSpinner)
    eventBus.$on('after-response', this.hideSpinner)
    eventBus.$on('response-error', this.hideSpinner)
  },
  beforeDestroy() {
    eventBus.$off('before-request', this.showSpinner)
    eventBus.$off('request-error', this.hideSpinner)
    eventBus.$off('after-response', this.hideSpinner)
    eventBus.$off('response-error', this.hideSpinner)
  },
  mounted() {},
  setup() {
    
    const { skin, skinClasses } = useAppConfig()
    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })
    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    return {
      skinClasses,
    }
  },
  methods: {
    showSpinner() {
      this.$isLoading(true)
    },

    // // sync user permission based on token and role
    // syncUserPermission() {
    //   if (window.localStorage.getItem('token') !== null) {
    //     store.dispatch('getAuthenticatedPermissions')
    //     // //console.log('true')
    //   }
    // },
    hideSpinner() {
      this.$isLoading(false)
    },
  },
}
</script>
<style>
[dir] .vs--disabled .vs__dropdown-toggle {
  cursor: not-allowed !important;
  background-color: #f8f8f8 !important;
}
.b-form-file .custom-file-label {
  overflow: hidden;
}
::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background-color: #0d6efd !important;
    border: 1px solid transparent;
    border-radius: 18px;
}

</style>
